/*
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */
* {
  margin: 0;
  box-sizing: border-box;
}

/* Dojo CSS Reset */
ul,
ol {
  margin-left: 0;
}

ol li,
ul li {
  font-size: inherit;
  line-height: inherit;
  list-style: none;
}

hr {
  margin: 0;
  border-top: 1px solid #999;
}

dl {
  font-size: 12px;
  color: #999;
}

dl.dl-horizontal dt {
  white-space: break-spaces;
  word-break: break-word;
}

dd.detailsSubSectionList {
  margin-left: 0px;
}

div.computeDetailsContainer dl.dl-horizontal dt {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

div.computeDetailsContainer dl.dl-horizontal dd {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

h1 {
  padding: 0;
  margin: 0 0 5px;
  font: normal 400 28px/1.20 "Roboto", Arial, Helvetica, sans-serif;
  color: #0076A8;
}

h2 {
  padding: 0;
  margin: 0 0 15px;
  font: normal 400 16px/1.20 "Roboto", Arial, Helvetica, sans-serif;
  color: #c04c0b;
}

.extraLinks {
  padding: 15px 30px 0;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

div#cloudCenterApp.appContainer {
  min-height: calc(100% - 85px);
}


/* ------------------------------------
$ Page Globals
------------------------------------ */
html {
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
}

/* iOS Fixes for Scrolling iframe */
html.dj_ios .ios_iframe_scroller {
  height: calc(100vh - 300px);
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

html body {
  height: 100%;
  min-width: 320px;
  padding: 0;
  margin: 0;
  background-color: #e6e6e6;
  color: #212121;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

html body.additionalDetailsShowing {
  background-color: #fff !important;
}

html body.unsupportedBrowser {
  background-color: #fff;
  color: #000;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding: 25px;
}

html body.unsupportedBrowser {
  background-color: #fff;
  color: #000;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding: 25px;
  vertical-align: top;
}

html body.modal-open {
  padding-right: 0 !important;
}

div#aboutPageView,
div#managementPageView,
div#computeResourcePageView,
div#computeResourceDetailPageView,
div#awsImportRoleContainerPageView,
div#awsCreateRoleContainerPageView,
div#awsLegacyParallelPageView,
div#credentialsPageView {
  background: #fff;
  min-height: calc(100vh - 86px);
  padding: 0px 100px;
}

div.aboutContainer,
div.managementContainer,
div.computeContainer,
div.awsImportRoleContainer,
div.awsCreateRoleContainer,
div.awsLegacyParallelContainer,
div.credentialsContainer {
  padding: 140px 15px 20px 15px;
}

div.aboutContainer {
  padding-top: 50px;
}

div.awsImportRoleContainer,
div.awsCreateRoleContainer,
div.awsLegacyParallelContainer {
  max-width: 759px;
}

div.aboutContainer>div.resourceContainer,
div.managementContainer>div.resourceContainer,
div.computeContainer>div.resourceContainer,
div.computeDetailsContainer>div.generatedCode>div.resourceContainer,
div.awsImportRoleContainer>div.resourceContainer,
div.awsCreateRoleContainer>div.resourceContainer,
div.awsLegacyParallelContainer>div.resourceContainer,
div.credentialsContainer>div.resourceContainer {
  margin: 0px;
  padding: 0px;
}

div.computeContainer>div.resourceContainer>div.resourceTitleBar,
div.computeDetailsContainer>div.generatedCode>div.resourceContainer>div.resourceTitleBar,
div.awsImportRoleContainer>div.resourceContainer>div.resourceTitleBar,
div.awsCreateRoleContainer>div.resourceContainer>div.resourceTitleBar,
div.awsLegacyParallelContainer>div.resourceContainer>div.resourceTitleBar,
div.aboutContainer>div.resourceContainer>div.resourceTitleBar,
div.managementContainer>div.resourceContainer>div.resourceTitleBar,
div.credentialsContainer>div.resourceContainer>div.resourceTitleBar {
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #212121;
  margin-bottom: 15px;
}

div.aboutContainer>div.resourceContainer>div.aboutInstanceContainer>h1:not(:first-child),
div.managementContainer>div.resourceContainer>div.managementInstanceContainer>h1:not(:first-child),
div.credentialsContainer>div.resourceContainer>div.credentialInstanceContainer>h1:not(:first-child) {
  padding-top: 20px;
  border-top: 2px solid #888;
}

div.aboutContainer div#aboutButtonsContainer,
div.managementContainer div#managementButtonsContainer,
div.credentialsContainer div.credentialButtonsContainer {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

div.noProductionServerMessageContainer,
div.noLicenseManagerMessageContainer,
div.noWebAppServerMessageContainer,
div.noOnlineServerMessageContainer,
div.noCfeDemoMessageContainer,
div.noCloudNetworkMessageContainer,
div.noParallelServerMessageContainer,
/* modern */
div.noClusterMessageContainer,
/* legacy */
div.noMatlabMessageContainer {
  background-color: #f5f5f5;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 225px;
  margin-bottom: 40px;
}

div.noProductionServerMessageContainer>div.noDataMsg,
div.noLicenseManagerMessageContainer>div.noDataMsg,
div.noWebAppServerMessageContainer>div.noDataMsg,
div.noOnlineServerMessageContainer>div.noDataMsg,
div.noCfeDemoMessageContainer>div.noDataMsg,
div.noCloudNetworkMessageContainer>div.noDataMsg,
div.noParallelServerMessageContainer>div.noDataMsg,
/* modern */
div.noClusterMessageContainer>div.noDataMsg,
div.noMatlabMessageContainer>div.noDataMsg {
  color: #212121;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: normal;
}



/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */
.headerContainer [class^="col-"] {
  min-height: 0;
  height: 100%;
}

h3.popover-header {
  position: relative;
}

h3.popover-header div.cc-popover-title {
  display: inline-block;
}

h3.popover-header div.cc-popover-helplink {
  position: absolute;
  top: 8px;
  right: 15px;
  background-color: inherit;
}

h3.popover-header div.cc-popover-helplink span.horizontalIconContainer {
  display: inline;
  padding: 0px;
  margin: 0px;
}

/* Focus Outlines */
a {
  color: #0076a8;
  text-decoration: none;
}

a:visited {
  color: #004b87;
  text-decoration: none;
}

a:hover {
  color: #004b87;
  cursor: pointer;
  text-decoration: underline;
}

a:focus,
button:focus,
.btn:focus {
  outline: 2px solid #268cdd !important;
  outline-offset: 2px;
}

.cta-btn:focus {
  outline-offset: -2px;
}

.header a:focus,
.header button:focus,
.header .btn:focus {
  outline-color: #b4deff !important;
}

footer a:focus,
footer button:focus,
footer .btn:focus {
  outline: 2px solid #212121 !important;
}

.resource-machine-name a:focus {
  outline-offset: 0;
}

a.sectionExpander:hover {
  opacity: 0.6;
}

/*
   mw button style overrides of Bootstrap
  */
.btn {
  padding: 10px 14px 9px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  line-height: 1;
  white-space: normal;
}

.btn:active,
.btn:focus,
.btn:hover {
  box-shadow: inset 0 0 0 200px rgb(255 255 255 / 20%);
}

.btn:focus,
a:focus,
button:focus {
  outline: 2px solid #268cdd !important;
  outline-offset: 2px;
}

.btn[class*=btn_color_] {
  color: #fff !important;
}

.btn.btn_color_blue,
.btn.btn_color_blue:visited {
  background: #0076a8;
}

[class*=companion_btn] {
  padding: 9px 13px 8px;
  border-style: solid;
  border-width: 1px;
  background: transparent !important;
}

[class*=companion_btn]:hover {
  opacity: 0.6;
}

.companion_btn.btn_color_blue,
.companion_btn.btn_color_blue:visited {
  border-color: #0076a8;
  color: #0076a8 !important;
}

.btn.btn_color_blue.companion_btn.toggleDetails {
  border: none !important;
}

.modal-dialog .btn.companion_btn {
  border: 1px solid #0076a8 !important;
}

.btn.btn_color_blue.companion_btn.viewPassword:focus {
  outline: none !important;
}


.disabled { opacity: 0.45; cursor: default; }
.disabled > button { cursor: default; }

input[type="checkbox"].disabled { opacity: 1.0; }

/* matrix */
li#headernav_matrix {
  top: -5px;
}

.headernav_matrix button {
  margin-bottom: 0px;
}

.headernav_matrix button>.icon-matrix {
  color: #fff !important;
}

li#headernav_matrix .matrix_container {
  right: 0px !important;
}

/* Add & Remove Classes */

/* ---- Additive / Reductive CSS ---- */

/* Add */

/* Add: Margin-Bottom */
.add_margin_0 {
  margin-bottom: 0px !important;
}

.add_margin_5 {
  margin-bottom: 5px !important;
}

.add_margin_10 {
  margin-bottom: 10px !important;
}

.add_margin_15 {
  margin-bottom: 15px !important;
}

.add_margin_20 {
  margin-bottom: 20px !important;
}

.add_margin_25 {
  margin-bottom: 25px !important;
}

.add_margin_30 {
  margin-bottom: 30px !important;
}

.add_margin_35 {
  margin-bottom: 35px !important;
}

.add_margin_40 {
  margin-bottom: 40px !important;
}

.add_margin_45 {
  margin-bottom: 45px !important;
}

.add_margin_50 {
  margin-bottom: 50px !important;
}

.add_margin_60 {
  margin-bottom: 60px !important;
}

.add_margin_70 {
  margin-bottom: 70px !important;
}

.add_margin_80 {
  margin-bottom: 80px !important;
}

.add_margin_90 {
  margin-bottom: 90px !important;
}

.add_margin_100 {
  margin-bottom: 100px !important;
}

/* Add: Indent */
.add_indent_10 {
  margin-left: 10px !important;
}

/* ---- Table ---- */
ol.collection {
  margin: 0px;
  padding: 0px;
  display: table;
  width: 100%;
}

div.header-menu {
  width: 40px;
}

div.header-actions {
  width: 100px;
}

div.header-status {
  width: 200px;
}

div.header-notifications span[class*=icon-alert-warning] {
  color: #c04c0b;
}

button.btn.displayNotificationsBtn span[class*=icon-alert-warning] {
  color: #c04c0b;
}

button.btn.displayNotificationsBtn span[class*=icon-alert-error] {
  color: #b7312c;
}

button.btn.displayNotificationsBtn {
  padding: 2px 3px 0px 3px;
  margin: 0px 2px;
  font-size: 24px;
  background-color: inherit;
  max-width: 55px;
}

div#wizardWarningDialog p {
  display: inline-block;
  padding-left: 45px;
}

div#wizardWarningDialog span[class*=icon-alert-warning] {
  color: #f0c300;
  font-size: 24px;
  position: absolute;
}

div.modal.noCredentialsWarning ul>li {
  list-style: disc;
  padding: 4px 0px;
}

/* Table: Tabular Layout */
.icon {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

.about-threedots:hover,
.management-threedots:hover,
.cred-type-threedots:hover {
  opacity: 1 !important;
}

.status-running {
  background-color: #008013;
}

.status-starting {
  background-color: #757575;
}

.status-stopped {
  background-color: #B7312C;
}

.status-pausing,
.status-stopping {
  background-color: #757575;
}

.status-paused {
  background-color: #b7312c;
}

.resource-status-parent {
  margin-left: 0px;
}

.resource-status {
  position: relative;
  text-align: unset;
  color: white;
  margin: 0px 26px 0px 0px;
  height: 26px;
}

/* Focus Outline Fixes */
div.attribute.resource-primary-cta {
  padding-left: 4px;
}

div.resource-status>div.progressbar {
  padding: 2px;
  width: 100%;
  background-color: transparent;
  padding-top: 4px;
  text-align: center;
}

div.resource-status>div.progressbar,
div.computeDetailsContainer div.resource-status>div.progressbar {
  padding: 0px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
}

div.resource-status>div.statusNameContainer,
div.computeDetailsContainer div.resource-status>div.statusNameContainer {
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

div.resource-status>div.statusNameContainer {
  text-align: center;
  padding: 3px 8px 0px 8px;
}

div.resource-status>div.progressbar.starting {
  background-color: #008013;
}

div.resource-status>div.progressbar.stopping {
  background-color: #B7312C;
}

span.disabled>button {
  cursor: not-allowed;
}

li.about-option,
li.management-option,
li.cred-type-option {
  padding: 2px 15px;
}

.about-option:focus,
.management-option:focus,
.cred-type-option:focus {
  background-color: rgb(212, 212, 212);
}

div#wizardCredTypeDialog button.btn.cred-action {
  border: none;
}

div#wizardCredTypeDialog button.btn.cred-action:hover {
  color: #004b87;
  cursor: pointer;
  text-decoration: underline;
}

input:focus,
select:focus,
textarea:focus {
  outline: 2px solid #268cdd !important;
  outline-offset: 2px;
}

/* ------------------------------------
$ Structure
------------------------------------ */
/* ---- Header ---- */
.header,
.headerContainer {
  background: #fff;
}

div#header_desktop_preload,
div#header_desktop {
  padding: 0px 103px;
  height: 100%;
}

div#header_desktop_preload {
  height: 52px;
  padding-top: 4px;
}

ul#cloud_center_nav,
ul#cloud_center_nav_mobile {
  color: #000;
  padding: 0px;
  margin: 0px;
  border: none;
  --bs-dropdown-border-radius: 0px;
}

.headerContainer {
  width: 100%;
  position: fixed;
  z-index: 3;
  height: 52px;
}

/* Global Navbars */
.navbar {
  min-height: 0;
  height: 100%;
  margin-bottom: 0;
  border: none;
}

.navbar-light {
  background-color: transparent;
}

.navbar-light .btn-link {
  height: 100%;
  padding: 0px;
}

.navbar-light .btn-link div.appTitle {
  padding: 0px;
  display: table-cell;
  vertical-align: middle;
}

.navbar .container-fluid {
  height: 100%;
}

.navbar .container-fluid .row {
  height: 100%;
}

.navbar-collapse {
  flex-grow: inherit;
}

.navbar-collapse ul {
  margin-bottom: 0;
  align-items: center;
}

.navbar-collapse li {
  padding-left: 0;
  margin-bottom: 0;
  background: none;
  font-size: 14px;
}

.navbar-header {
  float: none;
  height: 100%;
}

.navbar .btn:focus,
.navbar a:focus,
.navbar button:focus {
  outline: none !important;
}

/* FooterNav */
.footernav {
  overflow: hidden;
}

.footernav li {
  padding-left: 0;
  margin-bottom: 0;
  float: left;
}

.footernav li+li {
  border-left: 1px solid #808080;
}

.footernav a {
  padding: 0 8px;
  display: block;
}

.footernav li:first-child a {
  padding-left: 0;
}

.footernav li:last-child a {
  padding-right: 0;
}

/* ------------------------------------
$ Site Elements
------------------------------------ */

/*
    Icons and general classes
  */
.appTitle {
  padding: 7px 0px 3px;
  height: 45px;
}

.logoWrapper {
  white-space: nowrap;
}

.horizontalTextAndIconContainer {
  display: inline-block;
  white-space: nowrap;
}

.spinnerIcon {
  background-image: url(../icons/spinner.gif);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin: 5px 15px;
}

.smallIcon {
  display: inline-block;
  vertical-align: text-bottom;
  background-size: 16px;
  background-repeat: no-repeat;
  background-color: #0076A8;
  width: 16px;
  height: 16px;
  margin: 1px 3px;
}

.cta-btn .smallIcon {
  background-size: 24px;
  height: 24px;
  width: 24px;
  margin: 0px;
}

.spinnerIconClean {
  background-image: url(../icons/spinner.gif);
  background-color: unset;
}

.cloneIcon {
  background-image: url(../icons/copy.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.addIcon {
  -webkit-mask-image: url(../icons/add.svg);
  mask-image: url(../icons/add.svg);
}

.startIcon {
  background-image: url(../icons/Start_Active.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.stopIcon {
  -webkit-mask-image: url(../icons/Stop_Active.svg);
  mask-image: url(../icons/Stop_Active.svg);
}

.resumeIcon {
  -webkit-mask-image: url(../icons/Start_Active.svg);
  mask-image: url(../icons/Start_Active.svg);
}

.forceresumeIcon {
  -webkit-mask-image: url(../icons/Start_Active.svg);
  mask-image: url(../icons/Start_Active.svg);
}

.pauseIcon {
  -webkit-mask-image: url(../icons/Stop_Active.svg);
  mask-image: url(../icons/Stop_Active.svg);
}

.archiveIcon {
  -webkit-mask-image: url(../icons/trash.svg);
  mask-image: url(../icons/trash.svg);
}

.deactivateIcon {
  -webkit-mask-image: url(../icons/Stop_Active.svg);
  mask-image: url(../icons/Stop_Active.svg);
}

.viewPasswordIcon {
  -webkit-mask-image: url(../icons/eye.svg);
  mask-image: url(../icons/eye.svg);
}

.hidePasswordIcon {
  -webkit-mask-image: url(../icons/eye-slash.svg);
  mask-image: url(../icons/eye-slash.svg);
}

.refreshIcon {
  -webkit-mask-image: url(../icons/arrow-repeat.svg);
  mask-image: url(../icons/arrow-repeat.svg);
}

.editIcon {
  -webkit-mask-image: url(../icons/Edit_Active.svg);
  mask-image: url(../icons/Edit_Active.svg);
}

.editNameIcon {
  background-image: url(../icons/edit_credential.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin: 0px 10px 0px 0px;
  display: inline-block;
  vertical-align: text-bottom;
}

.cancelEditNameIcon {
  background-image: url(../icons/cancel_edit_name.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin: 0px 10px 0px 0px;
  display: inline-block;
  vertical-align: text-bottom;
}

.deleteIcon {
  background-image: url(../icons/trash.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.warningIcon {
  background-image: url(../icons/warning.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.cautionIcon {
  background-image: url(../icons/caution.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.renameIcon {
  background-image: url(../icons/rename.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.resetIcon {
  -webkit-mask-image: url(../icons/Stop_Active.svg);
  mask-image: url(../icons/Stop_Active.svg);
}

.copyTextIcon {
  -webkit-mask-image: url(../icons/clipboard.svg);
  mask-image: url(../icons/clipboard.svg);
}

.downloadRDPIcon {
  background-image: url(../icons/download.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.downloadDCVIcon {
  background-image: url(../icons/download.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.threeDotsVerticalIcon {
  -webkit-mask-image: url(../icons/three-dots-vertical.svg);
  mask-image: url(../icons/three-dots-vertical.svg);
}

.threeDotsVerticalIcon:hover {
  opacity: 1.0;
}

.connectIcon {
  background-image: url(../icons/Open_Active.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.checkIcon {
  background-image: url(../icons/check.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin: 0px 10px 0px 0px;
  display: inline-block;
  vertical-align: text-bottom;
}

.editCredentialIcon {
  background-image: url(../icons/edit_credential.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.deleteCredentialIcon {
  background-image: url(../icons/delete_credential.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  background-size: contain;
  display: inline-block;
  background-color: inherit !important;
}

.mathworks-membrane {
  display: inline-block;
}

.mathworks-membrane a {
  width: 32px;
  height: 32px;
  background: url(../icons/L-Membrane.svg) no-repeat center center;
  background-size: contain;
  display: block;
}

.membrane-divider {
  height: 22px;
  margin: 0 10px 5px 9px;
  border-right: 1px solid #bfbfbf;
  display: inline-block;
}

button.warning-notification>.icon-alert-warning {
  margin: 0px 5px;
  vertical-align: middle;
  color: #c04c0b;
}

.row.mainDetailTableList button.warning-notification>.icon-alert-warning {
  margin-left: 8px;
}

li>button.warning-notification {
  margin-left: 5px;
}

div#selectReleaseContainer button.warning-notification {
  padding: 0px;
  margin: 0px 0px 0px 14px;
  border: none;
  background-color: transparent;
}

div.mainDetailTable>dl.row.mainDetailTableList button.warning-notification {
  padding: 0px;
  margin: 0px 0px 0px 3px;
  border: none;
  background-color: transparent;
}

ul.detailsSubSectionList>li>button.warning-notification {
  padding: 0px;
  margin: 5px 0px 0px 3px;
  border: none;
  background-color: transparent;
}

button.warning-notification:hover,
button.warning-notification:active,
button.warning-notification:focus {
  box-shadow: inset 0 0 0 200px rgb(255 255 255 / 20%);
}

span.icon-alert-warning:hover,
span.icon-alert-warning:active,
span.icon-alert-warning:focus {
  opacity: 0.6;
}


.hide {
  display: none !important;
}

button.btn.copyOutputValueButton {
  padding: 4px 2px;
  margin-left: 8px;
}

li.item.item-container {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

li.item.item-container.table-header {
  font-weight: 700;
  color: #1a1a1a;
}

ol.collection-container .dropdown-toggle::after {
  display: none;
}

li.nav-item.headernav_login.dropdown a:hover {
  text-decoration: none;
}

form#addCredsForm {
  font-size: 14px;
}

form#addCredsForm .input-group-text {
  font-size: 14px;
}

a#createNewKeyPair {
  color: #0076a8;
  text-decoration: none;
}

.modal-dialog {
  margin: 30px auto;
  width: 600px;
  max-width: unset;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.modal-body {
  font-weight: 400;
  font-size: 14px;
}

.modal-header .btn-close {
  font-size: 12px;
}

dl.row dt,
dl.row dd {
  line-height: 1.42857143;
  margin: 0px;
  display: flex;
  align-self: flex-end;
}

dl.row dt.col-sm-3 {
  text-align: right;
  justify-content: end;
}

p.explanationText {
  margin-bottom: 10px;
}

.modal-content {
  line-height: 20px;
}

a#menu-about,
a#menu-management,
a#menu-logout {
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

#header_mobile .headernav_matrix>button#matrix_waffle_small {
  margin: 0px !important;
  padding: 0px;
}

#header_mobile .headernav_matrix>button#matrix_waffle_small:focus,
#header_mobile .headernav_matrix>button#matrix_waffle_small:visited {
  box-shadow: none;
}

#header_mobile .navbar-header {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#header_mobile nav.navbar.navbar-expand-lg.navbar-dark {
  padding: 0px;
}

#header_mobile .container-fluid {
  padding: 0px 5px;
}

#header_mobile a,
#header_mobile a:visited {
  text-decoration: none;
}

div#header_mobile.blockContainer {
  padding-top: 0px;
}

#header_mobile button.navbar-toggler.topnav_toggle {
  padding: 0px;
  border: none;
}

#header_mobile button.navbar-toggler.topnav_toggle:focus {
  box-shadow: none;
}

#header_mobile div.collapseContainer {
  width: 100%;
}

#header_mobile a.dropdown-toggle.show {
  color: #333;
  background: #e6ebef;
}

#topnav.navbar-nav.nav>li>a:focus,
#topnav.navbar-nav.nav>li>a:hover {
  text-decoration: none;
}

select#updateTerminationPolicyValue {
  display: inline-block;
  appearance: auto;
}

button.btn_color_blue:not(.companion_btn):hover {
  background-color: #0076a8 !important;
}

button.btn_color_blue.companion_btn:hover {
  border-color: #0076A8 !important;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  box-shadow: none;
  cursor: not-allowed;
  pointer-events: auto;
  opacity: .65;
}

.navbar-brand button.btn.btn-link.default-link {
  padding: 2px 6px 0px 6px;
}

button.navbar-toggler.topnav_toggle.collapsed {
  display: block;
}

.resource-action-dropdown-menu .dropdown-item.disabled,
.resource-action-dropdown-menu .dropdown-item:disabled,
.resource-action-dropdown-menu .btn.disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

  div.outputsContainer dt.outputsLabel { word-break: break-word; }
  div.outputsContainer dd.outputsValue { padding-right: 50px; position: relative; }
  div.outputsContainer dd.outputsValue button.btn.copyOutputValueButton { position: absolute; top: 0px; right: 15px; }

label.menuSubsection.dropdown-header {
  padding: 3px 15px;
  color: #212121;
  font-size: 14px;
  font-weight: bold;
}

ul.menuSubsectionList>li {
  padding: 0px !important;
}

ul.menuSubsectionList>li>button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

/* CloudCenter Login/Info Dropdown */
[id*="cloud_center_nav"] {
  padding: 5px 0px;
  font-size: 14px;
}

[id*="cloud_center_nav"]>.quotaListItem,
[id*="cloud_center_nav"]>.dropdown-email,
[id*="cloud_center_nav"]>.dropdown-showhiddenfiles,
[id*="cloud_center_nav"]>.dropdown-additionalApplications,
[id*="cloud_center_nav"]>.dropdown-username {
  padding: 3px 20px;
}

[id*="cloud_center_nav"]>.dropdown-showhiddenfiles .checkbox-showhiddenfiles {
  margin: 6px 0;
}

[id*="cloud_center_nav"]>.dropdown-additionalApplications .radio-additionalApplications {
  margin: 6px 10px;
}

[id*="cloud_center_nav"]>.dropdown-showhiddenfiles>label {
  font-weight: normal;
}

[id*="cloud_center_nav"]>.dropdown-additionalApplications label {
  font-weight: normal;
}

[id*="cloud_center_nav"]>.dropdown-additionalApplications>label {
  margin: 6px 0;
}

[id*="cloud_center_nav"]>.dropdown-username {
  color: #c45400;
  font-size: 15px;
}

[id*="cloud_center_nav"]>.dropdown-email {
  color: #595959;
}

.progress {
  height: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  background-color: #e6e6e6;
}

/* Facets, page navigation */
.facetBar {
  position: fixed;
  top: 52px;
  left: 0px;
  right: 0px;
  padding: 16px 5px 5px 100px;
  z-index: 1;
  background-color: #fff;
}

div.facetBar>ul {
  display: table;
  padding: 0px;
}

div.facetBar>ul>li {
  color: #212121;
  display: table-cell;
  padding: 0px 15px;
  border-right: solid 1px #D8D8D8;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
}

div.facetBar>ul>li:last-child,
div.facetBar>ul>li:only-child {
  border-right: none;
}

.horizontalIconContainer {
  padding-right: 5px;
  display: table-cell;
  vertical-align: middle;
}

.horizontalFlexContainer {
  padding: 2px 5px 0px 3px;
  display: flex;
  align-items: center;
}

.horizontalIconContainer.removeCollaborator {
  padding: 0px;
  vertical-align: inherit;
}

.horizontalIconContainer [class*="Icon"] {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  white-space: nowrap;
}

.horizontalFlexContainer [class*="Icon"] {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  white-space: nowrap;
}

a#helpLink {
  display: table;
}

#headernav>li>a#helpLink:hover {
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
}

li.nav-item.headernav_help {
  padding-top: 3px;
}

.helpIcon {
  background-image: url(../icons/info.svg);
}

.helpBlackIcon {
  background-image: url(../icons/info-black.svg);
}

.helpBlueIcon {
  background-image: url(../icons/info-blue.svg);
}

.userAvatarIcon {
  background-image: url(../icons/User_Avatar.svg);
  width: 24px !important;
  height: 24px !important;
}

.spinnerIcon {
  background-image: url(../icons/spinner.gif);
}

/* ---- Section Header (used in index.html) ----  */
.blockContainer {
  background-color: #0076A8;
  color: #fff;
  position: relative;
  padding-top: 3px;
}

.previewBannerContainer {
  display: inline-block;
  font-size: 24px;
  color: white;
  background: transparent;
  position: absolute;
  right: 70px;
  top: 8px;
  font-family: courier, sans-serif;
}

.section_header_content {
  width: 100%;
  height: 52px;
  padding: 8px 0 11px;
  display: table-cell;
  vertical-align: bottom;
}

.section_header_title {
  float: left;
}

.section_header h1,
.section_header p.h1 {
  padding: 0;
  margin-bottom: 0;
  color: #fff;
  font: normal 400 28px/1.00 "Roboto", Arial, Helvetica, sans-serif;
  letter-spacing: -.02em;
  float: left;
}

/* ---- Application Footer ---- */
.mainFooter {
  padding: 20px 15px;
  color: #808080;
  font-size: 12px;
  line-height: 1.25;
  background-color: #333;
  width: 100%;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.mainFooter>.col-xs-12 {
  float: none;
}

.mainFooter a,
.mainFooter a:focus,
.mainFooter a:hover,
.mainFooter a:visited {
  color: #808080;
}

.mainFooter p {
  margin-bottom: 6px;
}

.mainFooter ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 10px;
}

.mainFooter ul>li {
  list-style: none;
}

/* Action Buttons an button containers */
.actionIconContainer {
  position: fixed;
  top: 101px;
  left: 0px;
  right: 0px;
  padding: 10px 115px;
  margin: 0px;
  background-color: #fff;
  z-index: 1;
}

.actionIconContainer.detailsPage {
  position: static;
  top: unset;
  left: unset;
  right: unset;
  padding: 0px 0px 20px 0px;
  text-align: right;
  max-width: 760px;
  margin-left: 15px;
  border: none;
}

button.action_icon {
  display: inline-block;
  cursor: pointer;
  font: normal normal normal 14px/15px Roboto, sans-serif;
  border: 1px solid #0076A8;
  border-radius: 5px;
}

button.action_icon.btn-default {
  color: #3175A4;
}

button.action_icon.btn-primary {
  color: #fff;
}

.actionIconContainer>button.action_icon.btn:last-child {
  margin: 0px;
}

.actionIconContainer>button.action_icon>div {
  background-color: #0076A8;
  display: inline-block;
  height: 16px;
  width: 16px;
  white-space: nowrap;
  vertical-align: middle;
}

button.action_icon.btn-default>span.iconText {
  display: inline-block;
  color: #0076a8;
  vertical-align: middle;
}

button.action_icon.btn-primary>span.iconText {
  display: inline-block;
  color: #fff;
  vertical-align: middle;
}

.actionIconContainer>button.action_icon:active {
  opacity: 0.5;
}

.actionIconContainer>button.action_icon.disabled {
  cursor: not-allowed;
}

.actionIconContainer>button.action_icon.disabled:focus,
.actionIconContainer>button.action_icon.disabled:active,
.actionIconContainer>button.action_icon.disabled:hover {
  border: 1px solid #0076A8;
  background-color: #fff;
  opacity: 0.65;
}

/* Copy button */
button.copyIPValueButton {
  border: none;
  background-color: inherit;
}

button.copyIPValueButton:active {
  opacity: 0.5;
}

button.copyIPValueButton>div.copyTextIcon {
  background-color: #0076A8;
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: text-top;
}

/* Link buttons */
button.buttonMimicsLink {
  border: none;
  background-color: inherit;
  color: #0076a8;
}

button.buttonMimicsLink:hover {
  color: #004b87;
  cursor: pointer;
  text-decoration: underline;
  outline: 0;
}

button.buttonMimicsLink:focus {
  outline: 2px solid #268cdd !important;
  outline-offset: 2px;
}

/* CollapsibleSections */
div.collapsibleSection>h2.subtitle {
  color: #555;
  font-size: 22px;
  font-weight: 500;
  padding-left: 45px;
}

div.collapsibleSection>h3 {
  font-size: 14px;
  font-weight: 600;
  display: table-row;
  padding-left: 45px;
}

div.collapsibleSection>h3>div.detailsListItemLabel {
  display: table-cell;
  font-weight: inherit;
  color: #555;
  padding-left: 5px;
  width: 200px;
}

div.collapsibleSection>h3>div.detailsListItemValue {
  display: table-cell;
  font-weight: inherit;
  color: #8a8a8a;
  padding: 0px 50px;
}

div.collapsibleSection>h3>button {
  display: table-cell;
  border: none;
  font-weight: 500;
  background-color: inherit;
  font-size: 20px;
  vertical-align: middle;
}

div.collapsibleSection>h3>div.spinnerIconClean.smallIcon {
  display: table-cell;
  vertical-align: middle;
  margin-left: 20px;
}

/* detailsSubSetionList */
ul.detailsSubSectionList {
  display: table;
  width: 100%;
}

ul.detailsSubSectionList>li {
  list-style-type: none;
  padding: 8px 0px;
}

ul.detailsSubSectionList>li>div.detailsListItemLabel {
  display: table-cell;
  font-weight: 400;
  color: #555;
  padding-left: 5px;
  width: 200px;
}

ul.detailsSubSectionList>li>div.detailsListItemValue {
  display: table-cell;
  font-weight: normal;
  color: #555;
  padding-left: 50px;
}

/* dropdown menus */
ul.dropdown-menu.about-action-dropdown-menu,
ul.dropdown-menu.management-action-dropdown-menu,
ul.dropdown-menu.credential-action-dropdown-menu,
ul.dropdown-menu.resource-action-dropdown-menu {
  padding: 5px 0px;
  border: 1px solid #333;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

ul.dropdown-menu.about-action-dropdown-menu li,
ul.dropdown-menu.management-action-dropdown-menu li,
ul.dropdown-menu.credential-action-dropdown-menu li,
ul.dropdown-menu.resource-action-dropdown-menu li {
  font-size: 14px;
  color: #333 !important;
  padding: 3px 20px;
}

ul.dropdown-menu.resource-action-dropdown-menu li .smallIcon {
  margin: 0px 3px 0px 0px;
  vertical-align: middle;
  display: none;
}

ul.dropdown-menu li h2 {
  padding: 3px 20px;
  color: #0076A8;
  font-weight: 400;
  margin: 0px;
}

/* Additional Details */
div.additionalDetailContainer {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
}

div.additionalDetailHeader {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  background-color: inherit;
  color: #0076a8;
  padding: 0px 0px 5px 20px;
}

div.wizardStepWrapper div.additionalDetailHeader {
  margin: 10px 15px 0px 15px;
  padding: 10px 0px;
  width: calc(100% - 70px);
}

div.additionalDetailHeader>span.horizontalIconContainer>a.sectionExpander:hover {
  color: #004b87;
  text-decoration: none;
}

div.additionalDetailHeader>span.horizontalIconContainer>a.sectionExpander>h2 {
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  margin: 0px;
  color: #0076a8;
}

div.additionalDetailHeader>span.horizontalIconContainer>a.sectionExpander>div.sectionCollapsed,
div.additionalDetailHeader>span.horizontalIconContainer>a.sectionExpander>div.sectionExpanded {
  vertical-align: sub;
}

div.additionalDetailContainer>div.shortAddtionalDetail {
  padding: 0px 0px 0px 20px;
  font: normal normal normal 12px/20px Roboto, Arial, sans-serif;
  color: #6f6f6f;
}

div.additionalDetailContainer>div.shortAddtionalDetail>p {
  margin: 0px;
  padding: 0px 30px 0px 0px;
  white-space: normal;
}

div.additionalDetailContainer>div.generatedCode {
  padding: 0px;
  font: normal normal normal 12px/20px Arial, sans-serif;
  color: #6f6f6f;
}

div.additionalDetailContainer>div.generatedCode>h2 {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: #6f6f6f;
  margin: 5px 0px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList {
  padding: 0px 20px 0px 20px;
  margin: 0px;
  text-align: right;
  font-family: Roboto, sans-serif;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer {
  position: relative;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>button.addAccessButton {
  background-color: inherit;
  color: #0076A8;
  border: 1px solid #0076A8;
  line-height: normal;
  padding: 8px;
  margin: 10px 40px 0px 0px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>ul.detailsSubSectionList {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 0px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>ul.detailsSubSectionList>li {
  list-style-type: none;
  padding: unset;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>ul.detailsSubSectionList>li>div.detailsListItemLabel {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  width: 100px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>ul.detailsSubSectionList>li>div.detailsListItemValue {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding-left: 20px;
  max-width: 175px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>h3.subtitle {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  color: #6F6F6F;
  margin: 0px;
  padding: 0px 0px 0px 8px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>h3.subtitle>div {
  display: inline-block;
  padding-right: 5px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>h3.subtitle>button.removeAccessButton.btn {
  position: absolute;
  right: 15px;
  top: 10px;
  background-color: inherit;
  color: #0076A8;
  border: 1px solid #0076A8;
  line-height: normal;
  padding: 8px;
  margin-right: 40px;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>h3.subtitle>button.removeAccessButton.btn:active {
  opacity: 0.5;
}

div.additionalDetailContainer>div.generatedCode>div.accessWrapper div.accessList>div.accessPortContainer>h3.subtitle>div.spinnerIconClean.smallIcon {
  display: inline-block;
  margin-right: 80px;
}

div.additionalDetailContainer>div.generatedCode>ul {
  margin: 0px;
  padding: 0px 20px 0px 20px;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList {
  display: block;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li {
  display: list-item;
  padding: 0px;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li>div.detailsListItemLabel {
  display: inline-block;
  width: auto;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #6f6f6f;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li>div.detailsListItemValue {
  display: inline-block;
  width: auto;
  padding: 0px 8px;
  font-weight: normal;
  color: #6f6f6f;
}

div.additionalDetailContainer>div.generatedCode>dl>dd input.detailsListItemValue {
  background-color: inherit;
  margin-left: 0px;
  padding: 0px 5px;
  min-width: 200px;
  border: 1px solid #6f6f6f !important;
}

div.additionalDetailContainer>div.generatedCode>dl>dd button.viewPassword {
  vertical-align: sub;
  padding: 0px;
  position: relative;
  bottom: 1px;
  right: 25px;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li.vpcSubnet>div>div.detailsListItemLabel {
  width: auto;
  font-weight: bold;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li.vpcSubnet>div>div.detailsListItemValue {
  font-weight: normal;
  padding: 0px 0px 0px 8px;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li.vpcSubnet>ul.detailsSubSectionList {
  padding: 0px 0px 0px 20px;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li.vpcSubnet>ul.detailsSubSectionList>li {
  display: list-item;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li.vpcSubnet>ul.detailsSubSectionList>li>div.detailsListItemLabel {
  width: auto;
  font-weight: 400;
}

div.additionalDetailContainer>div.generatedCode>ul.detailsSubSectionList>li.vpcSubnet>ul.detailsSubSectionList>li>div.detailsListItemValue {
  padding: 0px 0px 0px 8px;
  font-weight: normal;
}

div.additionalDetailContainer dl>dd input.detailsListItemValue {
  display: table-cell;
  padding: 0 0 0 50px;
  border: none;
}

div.additionalDetailContainer dl>dd button.viewPassword {
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: inherit;
}

div.additionalDetailContainer dl .vpcSubnet {
  font-size: 13px;
  font-weight: bold;
  color: #333;
  overflow: visible !important;
  white-space: normal !important;
}

/* status and countdown */
.countdown-form-inline .form-control {
  width: auto;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

button#updateTerminationPolicyButton.form-inline.form-control.btn {
  height: auto;
}

div.attribute.statusAndCountdownContainer {
  height: 50px;
}

div.attribute.statusAndCountdownContainer div.countdown {
  text-align: center;
  margin-right: 52px;
}

div.attribute.statusAndCountdownContainer div.resource-countdown {
  display: none;
}

div.attribute.resource-cloud-provider {
  max-width: 300px;
}

li.item-container-for-cred-page>div.attribute-container>div.attribute {
  overflow: visible;
}

/* resourceTitleBar */
div.resourceTitleBar div.resourceContainerLabel {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource {
  display: inline-block;
  margin: 7px 10px 7px 0px;
}

div.resourceTitleBar>button.startNewMatlabButton,
div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>button.startNewMatlabButton {
  display: inline-block;
  color: #0076A8;
  border: 1px solid #0076A8;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  background-color: #fff;
  height: auto;
}

div.resourceTitleBar>button.startNewMatlabButton.disabled,
div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>button.startNewMatlabButton.disabled {
  opacity: 0.35;
  cursor: default;
  display: inline-block;
}

div.resourceTitleBar>button.startNewMatlabButton>.glyphicon,
div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>button.startNewMatlabButton>.glyphicon {
  margin-right: 5px;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: #333;
  padding: 0px 10px;
  border: 0.5px solid #707070;
  width: 207px;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li {
  padding: 7px 0px;
  border-bottom: 0.5px solid #707070;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li:last-child {
  border: none;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li:hover,
div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li:active {
  background-color: inherit;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li>a {
  padding: 0px;
  margin: 0px;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li>a>p {
  margin: 0px;
  padding: 0px;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li>a>p.title {
  font-size: 15px;
}

div.resourceTitleBar>div.dropdown.menu-container.createNewComputeResource>ul.dropdown-menu>li>a>p.finePrint {
  font-size: 10px;
}

div.resourceContainer>div.aboutInstanceContainer,
div.resourceContainer>div.managementInstanceContainer,
div.resourceContainer>div.credentialInstanceContainer {
  min-height: 225px;
  padding: 0px;
  background-color: #ffffff;
  position: relative;
}

div.resourceContainer>div.aboutInstanceContainer>h1,
div.resourceContainer>div.managementInstanceContainer>h1,
div.resourceContainer>div.credentialInstanceContainer>h1 {
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

div.resourceContainer>div.aboutContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.managementContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.credentialContainer>div.defaultResourceMsgContainer {
  text-align: center;
  color: #888;
  font-size: 16px;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

div.resourceContainer>div.parallelServerContainer,
div.resourceContainer>div.productionServerContainer,
div.resourceContainer>div.cloudNetworkContainer,
div.resourceContainer>div.cfeDemoContainer,
div.resourceContainer>div.licenseManagerContainer,
div.resourceContainer>div.webAppServerContainer,
div.resourceContainer>div.onlineServerContainer,
div.resourceContainer>div.clusterContainer,
div.resourceContainer>div.matlabContainer {
  min-height: 225px;
  border: none;
  padding-bottom: 50px;
}

div.resourceContainer>div.parallelServerContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.productionServerContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.cloudNetworkContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.cfeDemoContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.licenseManagerContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.webAppServerContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.onlineServerContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.clusterContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.clusterContainer>div.defaultResourceMsgContainer,
div.resourceContainer>div.matlabContainer>div.defaultResourceMsgContainer {
  text-align: center;
  color: #888;
  font-size: 16px;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

/*
  Compute details
*/
/* details page busy spinner */
div#detailsBusySpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  height: 100vh;
}

/* computeDetailsContainer */
div.computeDetailsContainer {
  padding: 0px 0px 20px 0px;
}

div.computeDetailsContainer>div.generatedCode {
  padding: 20px 15px;
}

div.computeDetailsContainer>h1.pageTitle {
  display: inline-block;
  color: #333;
}

div.computeDetailsContainer>button.editName {
  border: none;
  display: inline-block;
  padding-left: 0px;
}

div.computeDetailsContainer>div.providerResourceStatusContainer {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #6f6f6f;
  padding: 13px 15px;
}

div.computeDetailsContainer>h1.pageTitle {
  padding: 140px 0px 0px 15px;
  font: normal normal normal 28px/37px Roboto, sans-serif;
  color: #333;
  margin-right: 10px;
}

div.computeDetailsContainer>button.editName {
  opacity: 0.5;
  padding: 2px 0px;
  margin-right: 13px;
}

div.computeDetailsContainer>button.editName:hover {
  opacity: 1;
  background-color: inherit;
}

div.computeDetailsContainer>button.editName:visited {
  background-color: inherit;
}

div.computeDetailsContainer>button.editName:active {
  opacity: 0.5;
  background-color: inherit;
}

div.computeDetailsContainer>button.editName:focus {
  background-color: inherit;
}

/* status and IP container */
div.computeDetailsContainer div.IPOuterContainer {
  padding: 0px 20px;
}

div.computeDetailsContainer>div.statusAndIPContainer {
  display: block;
  padding: 10px 15px 10px 15px;
  margin: 0px 15px;
  position: relative;
  border: 1px solid #ddd;
  box-shadow: 0px 5px #ddd;
}

div.computeDetailsContainer div.statusAndCountdownContainer {
  display: inline-block;
  margin-left: 0px;
  margin-right: 8px;
  position: relative;
  left: 0px;
  text-align: center;
  width: auto;
  font: normal normal normal 12px/20px Roboto, sans-serif;
  vertical-align: middle;
}

div.computeDetailsContainer div.timeoutDropdownContainer {
  display: inline-block;
  margin-left: 0px;
  margin-right: 8px;
  position: relative;
  left: 0px;
  text-align: center;
  width: auto;
  font: normal normal normal 12px/20px Roboto, sans-serif;
  vertical-align: middle;
}

div.computeDetailsContainer div.timeoutDropdownContainer label.timeoutPolicyLabel {
  text-align: right;
  vertical-align: middle;
  max-width: 60px;
}

div.computeDetailsContainer div.statusAndCountdownContainer {
  padding-bottom: 5px;
}

div.computeDetailsContainer div.resource-status.status {
  display: inline-block;
  color: #fff;
  position: relative;
  left: 0px;
  text-align: center;
  width: 100%;
  font: normal normal bold 12px/20px Roboto, sans-serif;
  vertical-align: text-bottom;
  padding: 2px;
}

div.computeDetailsContainer div.IPContainer {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #6f6f6f;
  padding: 13px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
}

div.computeDetailsContainer div.IPContainer>span.ipLabel {
  color: #555;
  font-size: 12px;
  font-weight: 700;
  padding-left: 46px;
}

div.computeDetailsContainer div.IPContainer>span.ipValue {
  color: #555;
  font-weight: 400;
  font-size: 12px;
  padding-left: 15px;
}

div.computeDetailsContainer .form-control.btn-primary[disabled],
.form-control.btn-primary[readonly] {
  background-color: #0076a8;
}

div.computeDetailsContainer>div.releaseLicenseWarningContainer {
  margin: 20px 15px;
}

div.computeDetailsContainer button.btn.displayNotificationsBtn {
  vertical-align: baseline;
}

div.computeDetailsContainer div.resource-status>div.statusNameContainer {
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

/* computeResourceDetailCodeGenTemplate */
div.resourceDetailContainer {
  background-color: inherit;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  position: relative;
  max-width: 760px;
  padding: 20px;
  line-height: 22px;
}

div.resourceDetailContainer div.additionalDetailContainer>h2,
div.resourceDetailContainer>h1.resourceDetailContainerTitle {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #1a1a1a;
  display: inline-block;
}

div.resourceDetailContainer>div.mainDetailTable {
  margin-top: 15px;
}

div.resourceDetailContainer>div.mainDetailTable>ul.mainDetailTableList {
  padding: 0px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #6f6f6f;
  margin: 0px;
}

div.resourceDetailContainer>div.mainDetailTable>ul.mainDetailTableList>li {
  list-style: none;
}

div.resourceDetailContainer>div.mainDetailTable>ul.mainDetailTableList>li>span.configValueLabel {
  font-weight: bold;
}

div.resourceDetailContainer>div.mainDetailTable>ul.mainDetailTableList>li>span.configValue {
  font-weight: normal;
  padding: 0px 8px;
}

/* Details Edit form */
div.buttonContainer.editForm {
  position: static;
  text-align: right;
  padding: 0px 0px 20px 0px;
}

div.editFormContainer form.editConfigForm div.section2InputContainer button.viewPassword {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: inherit;
  padding: 3px 5px;
  height: 35px;
  width: 48px;
}

div.editFormContainer form.editConfigForm div.section2InputContainer.details>label {
  display: block;
  width: auto;
  max-width: none;
  text-align: left;
  margin-left: 40px;
  margin-right: 0px;
}

div.editFormContainer form.editConfigForm div.section2InputContainer .btn.toggleDetails {
  background-color: transparent;
  vertical-align: top;
  font-size: 20px;
  padding: 0px 12px;
}

div.editFormContainer form.editConfigForm div.section2InputContainer .btn.toggleDetails.pwd {
  position: relative;
  padding: 0px;
  right: 10px;
}

div.editFormContainer form.editConfigForm div.section2InputContainer.details>p.finePrint {
  display: block;
  font-size: 15px;
  font-weight: normal;
  width: auto;
  max-width: none;
  padding: 0px;
  margin-left: 55px;
  margin-right: 0px;
}

div.editFormContainer form.editConfigForm div.section2InputContainer.details>select,
div.editFormContainer form.editConfigForm div.section2InputContainer.details>textarea,
div.editFormContainer form.editConfigForm div.section2InputContainer.details>input[type="number"],
div.editFormContainer form.editConfigForm div.section2InputContainer.details>input[type="password"],
div.editFormContainer form.editConfigForm div.section2InputContainer.details>input[type="text"] {
  margin-left: 32%;
}

div.editFormContainer {
  margin: 20px 15px;
  max-width: 760px;
}

form.editConfigForm {
  font-family: Roboto, sans-serif;
}

form.editConfigForm>div.wizardSection {
  position: relative;
  width: 100%;
  min-width: 500px;
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 0px 0px 30px 0px;
  margin-bottom: 20px;
}

form.editConfigForm>div.wizardSection>div.header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 20px;
}

form.editConfigForm>div.wizardSection>div.header h1 {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: bold;
  color: #1a1a1a;
  display: inline-block;
  padding: 0px;
  margin: 0px;
}

form.editConfigForm>div.wizardSection>div.header div.linkContainer {
  position: absolute;
  right: 20px;
  display: inline-block;
}

form.editConfigForm>div.wizardSection>div.header a.editFormLink {
  color: #2077A8;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 0px 0px 8px;
}

form.editConfigForm div.wizardSection>div.collapsibleSection p.finePrint,
form.editConfigForm div.wizardSection>div.collapsibleSection div.stepComponentContainer>p.finePrint {
  display: none;
}

form.editConfigForm div.wizardSection>div.collapsibleSection>ul.disabled {
  padding: 0px 10px;
  opacity: 0.7;
}

form.editConfigForm div.wizardSection>div.collapsibleSection {
  padding: 0px 30px;
}

form.editConfigForm div.collapsibleSection>h2.subtitle {
  padding-left: 0px;
}

form.editConfigForm div.wizardSection>div.collapsibleSection div.stepComponentContainer {
  margin: 0px;
}

form.editConfigForm fieldset.section2Group legend {
  padding: 20px 0px 0px 0px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #1a1a1a;
  border: none;
  margin-bottom: 10px;
}

form.editConfigForm div.wizardSection>div.collapsibleSection div.stepComponentContainer>label {
  margin: 0px 55px 0px 0px;
  padding: 0px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  ;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  text-align: right;
}

form.editConfigForm div.wizardSection>div.collapsibleSection div#nameContainer.stepComponentContainer>label {
  margin: 0px;
  padding: 0px 20px 0px 0px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  text-align: right;
}

form.editConfigForm div.wizardSection>div.collapsibleSection div#nameContainer.stepComponentContainer {
  padding: 8px 0px;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>label {
  margin: 0px;
  padding: 0px 20px 0px 0px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  text-align: right;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer div.popover-content {
  word-wrap: break-word;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>div.passwordInputContainer {
  display: inline-block;
  position: relative;
  width: 60%;
  margin-left: 0px;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>div.passwordInputContainer>input {
  width: 100%;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset {
  display: inline-block;
  width: 90%;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset div.radioBtnContainer form.editConfigForm div.wizardSection>div.collapsibleSection div.stepComponentContainer input,
form.editConfigForm fieldset.section2Group>div.section2InputContainer .btn.toggleDetails form.editConfigForm div.wizardSection>div.collapsibleSection div.stepComponentContainer select,
form.editConfigForm fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset div.radioBtnContainer {
  padding-left: calc(30% + 20px);
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset legend {
  text-align: right;
  width: 30%;
  padding: 0px;
  color: #333;
  font-weight: bold;
  font-size: 13px;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset div.radioBtnContainer label {
  padding-left: 8px;
  color: #000;
  font-size: 15px;
  font-weight: normal;
  word-break: break-word;
  white-space: normal;
  vertical-align: top;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer>select,
form.editConfigForm fieldset.section2Group>div.section2InputContainer>textarea,
form.editConfigForm fieldset.section2Group>div.section2InputContainer>input[type="number"],
form.editConfigForm fieldset.section2Group>div.section2InputContainer>input[type="text"] {
  width: 60%;
  max-width: 450px;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 3px 10px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
}

form.editConfigForm input#mw-name {
  position: relative;
  left: -4px;
  width: 60%;
}

form.editConfigForm ul.detailsSubSectionList li select,
form.editConfigForm ul.detailsSubSectionList li input[type="text"] {
  width: 60%;
  max-width: 450px;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 3px 10px;
  margin-top: 2px;
  display: inline-block;
  vertical-align: top;
}

form.editConfigForm fieldset.section2Group {}

form.editConfigForm div.wizardSection>div.collapsibleSection div.stepComponentContainer {
  padding: 0px;
}

form.editConfigForm fieldset.section2Group>div.section2InputContainer {
  padding: 8px 0px;
  margin: 0px;
}

form.editConfigForm>div.wizardSection ul#nameProductList {
  opacity: 1.0;
  padding: 0px;
  margin: 0px;
  font: normal normal normal 15px/21px Roboto, sans-serif;
  color: #000;
}

form.editConfigForm>div.wizardSection ul.detailsSubSectionList>li>div.detailsListItemLabel {
  margin: 0px;
  padding: 0px 20px 0px 0px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  text-align: right;
}

form.editConfigForm>div.wizardSection ul.detailsSubSectionList>li>div.detailsListItemValue {
  display: inline-block;
  color: #000;
  font-weight: normal;
  font-size: 13px;
  padding: 8px 0px;
}

/*
    Create/Import Credentials Pages
  */
/* general - both */
form.addCredsForm .row {
  margin-right: 10px !important;
}

div.awsImportRoleContainer form div.dialogFoot,
div.awsCreateRoleContainer form div.dialogFoot {
  position: relative;
  padding: 20px 0px;
  border-top: 1px solid #e2e2e2;
  text-align: right;
}

div.awsImportRoleContainer form div.dialogFoot>button.btn.btn-primary,
div.awsCreateRoleContainer form div.dialogFoot>button.btn.btn-primary {
  background-color: #0076A8;
}

div.awsImportRoleContainer form div.dialogHead>h3,
div.awsCreateRoleContainer form div.dialogHead>h3 {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: bold;
}

/* Import Credential */
div.awsImportRoleContainer h2 {
  color: #000;
}

div.awsImportRoleContainer button.copyIPValueButton>div.copyTextIcon {
  background-color: #fff;
}

div.awsImportRoleContainer div.row.importSteps>div.stepContent {
  text-align: center;
}

div.awsImportRoleContainer div.row.importSteps>div.stepContent>p {
  text-align: left;
}

div.awsImportRoleContainer div.row.importSteps>div.stepContent>button.btn.credImportBtn {
  margin-bottom: 35px;
}

div.awsImportRoleContainer form div.dialogBody {
  padding-bottom: 15px;
}

div.awsImportRoleContainer form div.stepInfoText {
  padding: 0px 15px;
}

div.awsImportRoleContainer form div.stepInfoText>span.horizontalIconContainer>h2 {
  display: inline-block;
  padding: 0px 8px;
  width: calc(100% - 20px);
}

div.awsImportRoleContainer form div.stepInfoText>span.horizontalIconContainer>a.sectionExpander.collapsed {
  display: inline-block;
  vertical-align: top;
}

div.awsImportRoleContainer form div.stepInfoText>span.horizontalIconContainer>a.sectionExpander.collapsed>div.sectionExpanded {
  display: none;
}

div.awsImportRoleContainer form div.stepInfoText>span.horizontalIconContainer>a.sectionExpander.collapsed>div.sectionCollapsed {
  display: inline-block;
}

div.awsImportRoleContainer form div.stepInfoText>span.horizontalIconContainer>a.sectionExpander>div.sectionExpanded {
  display: inline-block;
}

div.awsImportRoleContainer form div.stepInfoText>span.horizontalIconContainer>a.sectionExpander>div.sectionCollapsed {
  display: none;
}

div.awsImportRoleContainer .stepTable {
  display: table;
  padding: 0px;
}

div.awsImportRoleContainer .stepRow {
  display: table-row;
}

div.awsImportRoleContainer .stepTdSpacer,
div.awsImportRoleContainer .stepTd {
  display: table-cell;
}

div.awsImportRoleContainer .stepTd .input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  height: 38px;
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* ------------------------------------
$ Section Specific
------------------------------------ */
/* ---- Login Page ---- */
.leftRightContainer {
  min-height: calc(100vh - 135px);
  padding: 25px 0;
  background: #fff;
}

.cloudCenterLogo {
  font-weight: 400;
  font-size: 24px;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #fff;
  line-height: normal;
  letter-spacing: -.02em;
}

div#clusterBar>div.resourceContainerLabel>a,
div#clusterBar>div.resourceContainerLabel>a:visited,
.btn.default-link,
.btn.default-link:visited {
  text-decoration: none;
}

.btn-logo {
  padding-left: 0;
}

button.btn.btn-logo {
  box-shadow: none !important;
}

div#clusterBar>div.resourceContainerLabel>a:hover,
.btn.default-link:hover {
  cursor: pointer;
  text-decoration: none;
}

/* Login page */
div.loginContainer div.navbar-brand {
  padding: 0px 0px 0px 100px;
}

div#header_desktop_login nav.navbar {
  padding: 0px;
}

iframe#loginIframe {
  border: none;
}

/* Embedded Login */
iframe.authIframe {
  width: 100%;
  height: 425px;
}

.login_container {
  max-width: 450px;
  margin: 0 auto;
}

.loginIntro {
  padding: 0 15px 10px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.loginIntro>.cloudCenterLogo {
  color: #0076A8;
}

.loginIntro>.cloudCenterLogin {
  color: #0076A8;
  text-align: center;
}


/* About, Management, Credentials Page */
div.aboutInstanceContainer li.item.item-container.item-container-for-about-page inline-popover,
div.managementInstanceContainer li.item.item-container.item-container-for-management-page inline-popover,
div.credentialInstanceContainer li.item.item-container.item-container-for-cred-page inline-popover {
  display: table-cell;
  vertical-align: middle;
}

div.aboutInstanceContainer li.item.item-container.item-container-for-about-page inline-popover a.btn.btn_color_blue.companion_btn.toggleDetails,
div.managementInstanceContainer li.item.item-container.item-container-for-management-page inline-popover a.btn.btn_color_blue.companion_btn.toggleDetails,
div.credentialInstanceContainer li.item.item-container.item-container-for-cred-page inline-popover a.btn.btn_color_blue.companion_btn.toggleDetails {
  padding-left: 0px;
}

h2.credPopoverh2 {
  font-size: 15px;
  color: #333;
  font-weight: 700;
  margin: 0px;
  padding: 3px;
}

h3.credPopoverh3 {
  font-size: 15px;
  color: #333;
  font-weight: 700;
  margin: 0px;
  padding: 3px;
}

p.credPopoverP {
  font-size: 14px;
  color: #333;
  margin: 0px;
  padding: 3px;
}

ul.credPopoverUl {
  padding-left: 5px;
}

p.credPopoverP a,
p.credPopoverP a:visited {
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #2077A8;
  display: inline-block;
}

p.credPopoverP a {
  color: #0076a8;
  text-decoration: none;
  font-weight: normal;
}

p.credPopoverP a:visited {
  color: #004b87;
  text-decoration: none;
}

p.credPopoverP a:hover {
  color: #004b87;
  cursor: pointer;
  text-decoration: underline;
}

p.credPopoverP a:focus,
button:focus,
.btn:focus {
  outline: 2px solid #268cdd !important;
  outline-offset: 2px;
}

div.credPopoverWarningIcon {
  width: 16px;
  height: 16px;
  background-image: url(../icons/error_notification.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.popover-placeholder {
  width: 50px;
  height: 50px;
}

/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

/*
   Input Prompt Dialog
  */
div#inputPromptDialog div.modal-body form div.fieldTable {
  display: table;
  border-spacing: 0px 10px;
  width: 100%;
}

div#inputPromptDialog div.modal-body form div.fieldTable>div.fieldTableRow {
  display: table-row;
  width: 100%;
}

div#inputPromptDialog div.modal-body form div.fieldTable>div.fieldTableRow>label {
  display: table-cell;
  width: 100px;
  vertical-align: middle;
  font-weight: 700;
}

div#inputPromptDialog div.modal-body form div.fieldTable>div.fieldTableRow>input {
  display: table-cell;
  width: 100%;
}

div#inputPromptDialog div.modal-body form div.fieldTable>div.fieldTableRow>textarea {
  width: 100%;
}

div#inputPromptDialog div.modal-body form div.fieldTable>div.fieldTableRow>select {
  width: 100%;
}

div#inputPromptDialog div.modal-body div.errorTextContainer {
  visibility: hidden;
  border: 1px solid #c13636;
  background: #faf0f0;
  padding: 5px;
}

div#inputPromptDialog div.modal-body div.errorTextContainer div.validationErrorImage {
  width: 16px;
  height: 16px;
  background-image: url(../icons/error_notification.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

div#inputPromptDialog div.modal-body div.errorTextContainer p.errorText {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  color: #1a1a1a;
  font-size: 15px;
  padding: 5px;
  margin: 0px;
  left: -25%;
  white-space: normal;
  word-break: normal;
  vertical-align: middle;
}

/*
  ** ConnectCredentialsDialog
  */
div#connectCredentialsDialog {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

div#connectCredentialsDialog ul {
  list-style-type: none;
  padding-left: 5px;
}

div#connectCredentialsDialog ul>li>label {
  margin-right: 8px;
  font-weight: normal;
}

div#connectCredentialsDialog ul>li>div {
  display: inline-block;
  font-weight: bold;
}

div#connectCredentialsDialog ul>li>input {
  font-weight: bold;
}

div#connectCredentialsDialog ul>li>input#password {
  width: 250px;
}

div#connectCredentialsDialog form ul li button.viewPassword {
  border: none !important;
  background-color: inherit !important;
  position: relative;
  right: 43px;
}

/*
  ** CopyToClipboardDialog
  */
div#copyToClipboardDialog {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

div#copyToClipboardDialog .modal-body ::selection {
  background-color: inherit;
}

div#copyButtonSpinner {
  display: inline-block;
  margin-left: 68px;
  margin-right: 72px;
  background-color: inherit;
}

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */
/* wizard general */
div.wizardContainer {
  padding: 100px 0px 20px 0px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer .btn.toggleDetails {
  background-color: transparent;
  vertical-align: top;
  font-size: 20px;
  padding: 0px 2px;
  margin-left: 10px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer .btn.toggleDetails.pwd {
  position: relative;
  right: 20px;
  padding: 0px;
}

div.wizardSection label.stepLegend {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #1a1a1a;
  margin: 10px 35px 0px 35px;
  padding: 10px 0px;
  width: calc(100% - 70px);
}

form div.wizardSection>div.collapsibleSection>ul.disabled {
  padding: 0px 10px;
  opacity: 0.7;
}

form div.wizardSection>div.collapsibleSection {
  padding-left: 45px;
}

form[name="editConfigForm"] div.collapsibleSection>h2.subtitle {
  padding-left: 0px;
}

.collapsed {
  display: none;
}

.cloudChoiceAwsButtonIcon {
  background-image: url(../icons/info_16.svg);
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  vertical-align: top;
}

.cloudChoiceAzureButtonIcon {
  background-image: url(../icons/info_16.svg);
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  vertical-align: top;
}

.osChoiceWindowsButtonIcon {
  background-image: url(../icons/info_16.svg);
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  vertical-align: top;
}

.osChoiceLinuxButtonIcon {
  background-image: url(../icons/info_16.svg);
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
}

.sectionExpanded {
  background-image: url(../icons/arrow-open-down.svg);
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  color: #444;
  display: inline-block;
  vertical-align: top;
}

.sectionCollapsed {
  background-image: url(../icons/arrow-open-right.svg);
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  color: #444;
  display: inline-block;
  vertical-align: top;
}

.serverIcon {
  background-image: url(../icons/server.svg);
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.buttonContainer>button:last-child {
  margin-right: 0px;
}

.buttonContainer>button#cancelWizardButton1,
.buttonContainer>button#cancelWizardButton2 {
  margin-left: 5px;
}

div.stepsConfigInfoContainer {
  width: auto;
  display: inline-block;
  vertical-align: top;
}

div#section1SummaryBusySpinnerContainer,
div#section1BusySpinnerContainer,
div#section2BusySpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
}

/* section 1 */
div.wizardContainer {
  position: relative;
  height: 100%;
}

div.wizardContainer div.wizardStepsContainer>h1 {
  color: #333;
  font-size: 28px;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  padding: 20px 0px;
  margin: 0px;
}

div.wizardContainer div.wizardStepsContainer>p.editExplanation {
  display: none;
  margin: 0px;
  padding: 25px 0px 5px 0px;
  width: calc(100% - 360px);
  max-width: 759px;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: #333;
}

div.wizardContainer div.wizardStepsContainer>p.editExplanation.autoComplete {
  display: none;
}

div.wizardContainer div.wizardStepsContainer {
  padding: 20px 15px 20px 15px;
  position: relative;
}

div.wizardContainer div.wizardStepContainer>h1 {
  color: #333;
  font-size: 28px;
  text-align: left;
}

div.wizardContainer div.wizardStepContainer {
  padding: 20px 0px;
  position: relative;
  max-width: 759px;
}

div.wizardContainer div.wizardBody {
  display: inline-block;
  width: calc(100% - 360px);
  max-width: 759px;
  margin-right: 10px;
}

div.wizardContainer div.wizardSection {
  position: relative;
  width: 100%;
  min-width: 759px;
  max-width: 759px;
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin-bottom: 20px;
}

div.wizardContainer div.wizardSection a.sectionExpander.disabled {
  opacity: 0.35;
  cursor: default !important;
}

div.wizardContainer div.wizardSection>div.header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 20px;
}

div.wizardContainer div.wizardSection>div.header>span.horizontalIconContainer>h1 {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: bold;
  color: #1a1a1a;
  display: inline-block;
  padding: 0px;
  margin: 0px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer {
  margin: 10px 0px 20px 0px;
  padding: 0px 15px;
}

div.wizardContainer div.stepComponentContainer.buttonContainer {
  text-align: right;
  max-width: 759px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer>label {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  padding: 0px 20px 0px 0px;
  text-align: right;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer>p.finePrint {
  display: none;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer input,
div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer select {
  width: 60%;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 3px 10px;
  display: inline-block;
  vertical-align: top;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer input:focus,
div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer select:focus {}

div.wizardContainer div.wizardBody>div#step1Container div.buttonContainer.stepComponentContainer>button.configInfoButton,
div.wizardContainer div.wizardBody>div#step2Container div.buttonContainer.stepComponentContainer>button.configInfoButton {
  display: none;
}

div.wizardContainer div.pickCredentialContainer button.btn.btn-default,
div.wizardContainer div.buttonContainer.stepComponentContainer button.btn.btn-default {
  border: 1px solid #0076A8;
  color: #0076A8;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoiceContainer {
  padding: 5px 0px;
  display: inline-block;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice {
  display: inline-block;
  vertical-align: top;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0px;
  height: 0px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  width: 125px;
  height: 60px;
  text-align: left;
  margin-right: 5px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice span.horizontalIconContainer {
  white-space: nowrap;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice>div.credentialButtonLabel {
  color: #1a1a1a;
  font-size: 14px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice>div.finePrint {
  display: none;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice h2 {
  display: inline-block;
  padding: 0px 5px;
  white-space: normal;
  word-break: break-word;
  text-align: left;
  color: #1a1a1a;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice.dynamicBtn:focus-visible,
div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice.dynamicBtn.selected:focus-visible {}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer {
  padding: 5px 0px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer>label {
  display: inline-block;
  font-size: 15px;
  color: #333;
  width: 30%;
  padding: 0px 20px 0px 20px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer>select {
  display: inline-block;
  padding: 4px;
  max-width: 365px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer>button {
  display: inline-block;
  margin-left: 5px;
  margin-right: 0px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoiceContainer {
  padding: 5px 0px;
  display: inline-block;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice {
  display: inline-block;
  vertical-align: top;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0px;
  height: 0px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>button.osChoice {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  width: 130px;
  height: 60px;
  text-align: left;
  margin-right: 5px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>button.osChoice span.horizontalIconContainer {
  white-space: nowrap;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice div.osButtonLabel {
  display: inline-block;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>button.osChoice h2 {
  display: inline-block;
  white-space: normal;
  word-break: break-word;
  text-align: center;
  color: #1a1a1a;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>button.osChoice.dynamicBtn:focus-visible,
div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>button.osChoice.dynamicBtn.selected:focus-visible {}

div.wizardContainer div.wizardBody>div.buttonContainer button {
  margin-right: 5px;
}

div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice>button.cloudChoice.selected,
div.wizardContainer div.wizardSection>div.collapsibleSection div.stepComponentContainer div.osChoice>button.osChoice.selected {
  width: 130px;
  background-color: #d5d5d5 !important;
  border: 1px solid #ccc !important;
}

/* section 2 */
div.wizardContainer div.generatedCodeContainer {
  padding-top: 10px;
}

div.wizardContainer div.generatedCodeContainer {
  padding: 0px 15px;
}

div.wizardContainer fieldset.section2Group {
  margin-top: 10px;
}

div.wizardContainer fieldset.section2Group legend {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #1a1a1a;
  padding: 0px 0px 0px 20px;
  border: none;
  margin-bottom: 10px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer {
  position: relative;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer div.popover-content {
  word-wrap: break-word;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer .radioCheckbox {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset {
  display: inline-block;
  width: 90%;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset div.radioBtnContainer {
  padding-left: calc(30% + 20px);
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset div.radioBtnContainer label {
  padding-left: 8px;
  color: #000;
  font-size: 15px;
  word-break: break-word;
  white-space: normal;
  vertical-align: top;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>fieldset legend,
div.wizardContainer fieldset.section2Group>div.section2InputContainer>label {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  padding: 0px 20px 0px 0px;
  text-align: right;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>label {
  display: block;
  width: auto;
  text-align: left;
  margin-left: 40px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>label.groupTitle {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: bold;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>fieldset label.radioCheckboxLabel {
  color: #333;
  font-size: 15px;
  display: inline-block;
  font-weight: normal;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>fieldset.radioCheckboxFieldset legend {
  text-align: right;
  width: 30%;
  padding: 0px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>p.finePrint {
  display: none;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>p.finePrint {
  display: block;
  font-size: 15px;
  font-weight: normal;
  width: 60%;
  padding: 0px;
  margin-left: 55px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer {
  padding: 8px 0px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>div.passwordInputContainer {
  display: inline-block;
  position: relative;
  width: 60%;
  margin-left: 0px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>div.passwordInputContainer>input {
  width: 100%;
  padding: 3px 10px;
  margin-top: 3px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>select,
div.wizardContainer fieldset.section2Group>div.section2InputContainer>textarea,
div.wizardContainer fieldset.section2Group>div.section2InputContainer>input[type="number"],
div.wizardContainer fieldset.section2Group>div.section2InputContainer>input[type="text"] {
  width: 60%;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 3px 10px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>select,
div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>textarea,
div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>input[type="password"],
div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>input[type="number"],
div.wizardContainer fieldset.section2Group>div.section2InputContainer.details>input[type="text"] {
  margin-left: 30%;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>input[type="checkbox"],
div.wizardContainer fieldset.section2Group>div.section2InputContainer>input[type="radio"] {
  width: auto !important;
  color: #333;
  font-size: 13px;
  font-weight: normal;
  display: inline-block;
  border: none;
  padding: 3px 10px;
  margin-top: 3px;
  margin-right: 5px;
  margin-left: 15px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>textarea {
  width: 60%;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>div.validationErrorImage {
  position: absolute;
  background-image: url(../icons/error_notification.svg);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  top: 41px;
  left: 440px;
  z-index: 0;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>div.validationErrorImage.fineprint {
  top: 19px;
  left: 600px;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer>input.password {
  display: inline-block;
}

div.wizardContainer fieldset.section2Group>div.section2InputContainer button.viewPassword {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: inherit;
}

/* summary */
div.wizardSummary {
  position: relative;
  top: -10px;
  padding: 0px;
  background-color: #fff;
}

div.wizardSummary>p {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  color: #333;
  max-width: 759px;
  letter-spacing: 0.02px;
}

div.summaryListContainer {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #e2e2e2;
  padding: 20px;
  width: 100%;
  max-width: 759px;
  background-color: inherit;
  margin-top: 15px;
  position: relative;
}

div.summaryListContainer.first {
  padding-left: 0px;
}

div.summaryListContainer.last {
  padding-right: 0px;
  border-right: none;
}

div.wizardSummary h1 {
  padding: 20px 0px;
  margin: 0px;
  color: #333;
  font-size: 28px;
  text-align: left;
  font-family: Roboto, sans-serif;
}

div.wizardSummary div.additionalDetailContainer>h2,
div.wizardSummary>div.summaryListContainer>h2 {
  color: #1a1a1a;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
}

div.wizardSummary>div.summaryListContainer>a {
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #2077A8;
  display: inline-block;
  position: absolute;
  right: 20px;
}

div.wizardSummary>div.summaryListContainer>dl.wizardCompleteSummaryList {
  padding: 0px;
}

div.wizardSummary>div.summaryListContainer>dl.wizardCompleteSummaryList>li>span {
  color: #6f6f6f;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

div.wizardSummary>div.summaryListContainer>dl.wizardCompleteSummaryList>li>span.configValueLabel {
  font-weight: bolder;
  padding-right: 10px;
}

div.wizardSummary>div.summaryListContainer>dl.wizardCompleteSummaryList>li>span.configValue {
  font-weight: normal;
}

div.wizardSummary>div.summaryListContainer>dl.wizardCompleteSummaryList>li>ul.extras>li>span {
  color: #6f6f6f;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

div.wizardSummary>div#summaryButtons {
  padding: 20px 0px;
}

div.wizardSummary>div#summaryButtons>button {
  outline-offset: 2px;
}

div.wizardSummary>div#summaryButtons button#editButton>span.progressSpinnerContainer {
  margin-left: -18px;
  padding: 0px 2px;
}

div.wizardSummary>div#summaryButtons button#editButton>span.progressSpinnerContainer>mw-progress-indicator {
  margin-right: 6px;
}

/* config info */
.configInfoIcon {
  background-image: url(../icons/info_16.svg);
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  vertical-align: top;
  margin-right: 5px;
}

div.configInfoContainer {
  width: 345px;
  display: inline-block;
  background-color: #fff;
  min-height: 280px;
  vertical-align: top;
  font-family: Roboto, sans-serif;
  color: #333;
}

div.configInfoContainer>div.configInfoHeader {
  color: #333;
  font-size: 15px;
  text-align: left;
  padding: 15px 0px 0px 0px;
}

div.configInfoContainer>div.configInfoHeader span.horizontalIconContainer h2 {
  display: inline-block;
  color: #333;
  font-size: 17px;
  font-weight: bold;
  margin: 0px 0px 5px 0px;
}

div.configInfoContainer>div.configInfoBox>ul {
  max-height: 51px;
  overflow-y: auto;
}

div.configInfoContainer>div.configInfoBox>div.configInfoTitle span.horizontalIconContainer {
  white-space: nowrap;
}

div.configInfoContainer>div.configInfoBox>div.configInfoTitle span.horizontalIconContainer h2 {
  display: inline-block;
  color: inherit;
  font-size: inherit;
  white-space: normal;
  word-break: break-word;
  padding-right: 5px;
  width: calc(100% - 21px);
}

div.configInfoBox {
  padding: 5px;
  background-color: #0076A80D;
  border: 1px solid #0076A8;
  border-radius: 4px;
  color: #000000D9;
  height: 120px;
}

div.configInfoBox>div.configInfoTitle {
  font-size: 14px;
  font-weight: 500;
}

div.configInfoBox ul>li {
  font-size: 12px;
  color: #000000D9;
  font-weight: 400;
}

div.configInfoContainer>div.matlabConfigurationBox {
  margin: 10px 0px;
  padding: 10px;
  border: 1px solid #ccc;
  border-top: 3px solid #0076A8;
  border-radius: 4px;
  background-color: #fff;
  display: none;
}

div.configInfoContainer>div.matlabConfigurationBox h1 {
  color: #1A1A1A;
  font-size: 15px;
  font-weight: 500;
}

div.configInfoContainer>div.matlabConfigurationBox ul {
  color: #6F6F6F;
  font-size: 12px;
  font-weight: normal;
  padding: 0px;
}

div.configInfoContainer>div.matlabConfigurationBox ul>li {
  list-style-type: none;
  padding: 3px;
}

div.configInfoContainer>div.matlabConfigurationBox>ul>li.highlight {
  padding: 3px;
  border-radius: 4px;
  background-color: #f5f5f5;
}

div.configInfoContainer>div.matlabConfigurationBox>ul>li>span.required {
  color: red;
  font-weight: bold;
  font-style: italic;
  font-size: 13px;
}

div.configInfoContainer>div.configInfoHeader>button.btn-close {
  display: none;
}

/* section 2 edit */
div.editFormContainer div.section2InputContainer button.viewPassword {
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: inherit;
}

div.editFormContainer div.section2InputContainer input[type="password"],
div.editFormContainer div.section2InputContainer input[type="text"] {
  display: inline-block;
  vertical-align: bottom;
}

/* autoComplete settings */
div.wizardStepContainer.autoComplete,
div.stepsConfigInfoContainer.autoComplete {
  display: none;
}

div#initialCreateButtonClickSpinner,
div#pageNavPreloadSpinnerContainer,
div#autoCompleteSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  width: calc(100vw - 160px);
}

div#initialCreateButtonClickSpinner>div.spinnerIcon,
div#pageNavPreloadSpinnerContainer>div.spinnerIcon,
div#autoCompleteSpinnerContainer>div.spinnerIcon {
  position: absolute;
  left: 0px;
}

h1.autoComplete {
  display: none;
}

div#step2ContentContainer.autoComplete {
  visibility: hidden;
}

div#step1ContentContainer.autoComplete {
  visibility: hidden;
}

/* align the spinner with the padding from createWizardStepsContainer */
/* margin-top, margin-right, margin-bottom, and margin-left */
div#pageNavPreloadSpinnerContainer,
div#initialCreateButtonClickSpinner {
  margin: 20px 0px 0px 15px;
}

/* ------------------------------------
$ Inline Validation
------------------------------------ */
div.section2InputValidationContainer {
  position: relative;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  padding-right: 7px;
  padding-left: 11px;
}

div.section2InputValidationContainer input[type="number"],
div.section2InputValidationContainer input[type="password"],
div.section2InputValidationContainer input[type="text"],
div.section2InputValidationContainer select {
  width: 100%;
  cursor: pointer;
  padding: 3px 10px;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
}

div.section2InputValidationContainer input[type="radio"],
div.section2InputValidationContainer input[type="checkbox"] {
  width: auto;
}


div.section2InputValidationContainer fieldset.radioCheckboxFieldset {
  display: inline-block;
  width: 90%;
}

div.section2InputValidationContainer fieldset.radioCheckboxFieldset div.radioBtnContainer {
  padding-left: 0;
  white-space: nowrap;
}

div.section2InputValidationContainer fieldset.radioCheckboxFieldset div.radioBtnContainer label {
  color: #000;
  font-size: 15px;
  padding-left: 8px;
  vertical-align: top;
  white-space: normal;
  word-break: break-word;
}

div.section2InputValidationContainer fieldset.radioCheckboxFieldset legend {
  padding: 0;
  text-align: right;
  width: 30%;
}

div.mainContainer div.login_container .errorTextContainer {
  display: none;
  border: 1px solid #c13636;
  background: #faf0f0;
  padding: 5px;
}

div.mainContainer div.login_container .errorTextContainer .errorText {
  font-family: Arial, Helvetica, sans-serif;
  color: #1a1a1a;
  font-size: 15px;
  padding: 10px 0px;
  left: -25%;
  width: 700px;
}

/* Inline Validation: Form Error Indicator (Arrow) */
.inline_form_error_msg {
  padding: 8px 15px 7px;
  margin: 0 0 20px;
  background: #b7312c;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: 12px;
}

.inline_form_error_arrow {
  width: 0;
  height: 0;
  margin: 6px 0px 0px 10px;
  border: 6px solid transparent;
  border-bottom-color: #b7312c;
  border-width: 0 6px 6px 6px;
  margin-left: 20px;
}

.validationErrorImage.form-control-feedback.fineprint {
  position: absolute;
  background-image: url(/assets/icons/error_notification.svg);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  top: 9px;
  right: 35px;
  z-index: 0;
}

.inline_form_error a,
.inline_form_error a:visited {
  color: #fff;
  text-decoration: underline;
}

.inline_form_error a:hover {
  color: rgba(255, 255, 255, .8);
}

.inline_form_error *:last-child {
  margin-bottom: 0;
}

/* Create Wizard Step1 mlNameInput-only */
div#nameContainer {
  position: relative;
}

div#nameContainer .inline_form_error_msg.mlNameInput {
  position: relative;
  left: 220px;
}

div#nameContainer .inline_form_error_arrow {
  position: relative;
  left: 220px;
}

div#nameContainer .validationErrorImage.form-control-feedback.fineprint {
  top: 15px;
  right: 95px;
}

div#nameContainer .inline_form_error_msg {
  position: relative;
  left: 210px;
}

/* ------------------------------------
  $ Custom Elements
  ------------------------------------ */

/* auto-login */
auto-login {
  position: relative;
  left: -4px;
}

auto-login select.disabled {
  cursor: not-allowed;
  opacity: .45;
}

auto-login div.autoLoginRadioButtonLayout {
  display: inline-block;
  width: 60%;
  padding-left: 11px;
  white-space: nowrap;
}

auto-login div.autoLoginRadioButtonLayout div.radioButtonContainer {
  margin-right: 15px;
  white-space: nowrap;
  display: inline-block;
}

auto-login label.paramLabel {
  vertical-align: top;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  padding: 0px 16px 0px 0px;
  text-align: right;
}

  /* license-manager, license-manager-simple */
  stack-rollback-choice { position: relative; left: -3px; }
  license-manager-simple,
  license-manager { position: relative; left: -7px; }
  license-manager-simple .section2InputValidationContainer { left: -7px; }
  license-manager .section2InputValidationContainer { position: relative; width: 100% !important; }
  license-manager-simple .section2InputValidationContainer select,
  license-manager .section2InputValidationContainer input[type="text"] { width: 100%; }
  license-manager-simple,
  license-manager { display: inline-block; width: 100%; height: 100%; }
  license-manager-simple .labelContainer,
  license-manager .labelContainer { position: relative; width: 30%; height: 100%; min-height: 59px;
    background-color: #fff; vertical-align: top; display: inline-block; }
  license-manager-simple div.labelContainer > label,
  license-manager div.labelContainer > label { position: absolute; top: 0px; right: 0px; color: #333;
    font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; display: inline-block;
    padding: 0px 20px 0px 0px; text-align: right; }
  license-manager-simple > label { width: 30%; }
  license-manager-simple > input,
  license-manager > input { width: 60%; color: #000; font-size: 15px; border: 1px solid #808080;
    border-radius: 4px; padding: 3px 10px; margin-top: 3px; display: inline-block; vertical-align: top; }
  license-manager-simple  a.btn.btn_color_blue.companion_btn.toggleDetails,
  license-manager  a.btn.btn_color_blue.companion_btn.toggleDetails { vertical-align: top; font-size: 20px;
    padding: 0px 2px; margin-left: 10px; border: none !important; color: #0076a8!important; background: transparent!important; }
  license-manager-simple fieldset.licenseManagerFieldset,
  license-manager fieldset.licenseManagerFieldset { display: inline-block; width: 60%; }
  license-manager fieldset.licenseManagerFieldset div.radioButtonContainer { margin-bottom: 5px; white-space: nowrap; display: inline-block; }
  license-manager-simple fieldset.licenseManagerFieldset label,
  license-manager fieldset.licenseManagerFieldset label { padding-left: 8px; padding-right: 10px;
    font-family: Roboto, Arial, Helvetica, sans-serif; font-size: 15px; color:#000; }
  license-manager-simple fieldset.licenseManagerFieldset input[type="text"],
  license-manager fieldset.licenseManagerFieldset input[type="text"] { width: 100%; cursor: pointer; padding: 3px 10px; }
  license-manager fieldset.licenseManagerFieldset input[type="text"].disabled { cursor: not-allowed; }
  license-manager-simple fieldset.licenseManagerFieldset .inline_form_error_msg,
  license-manager fieldset.licenseManagerFieldset .inline_form_error_msg { margin: 0; }
  license-manager-simple fieldset.licenseManagerFieldset .inline_form_error_arrow,
  license-manager fieldset.licenseManagerFieldset .inline_form_error_arrow { margin-left: 20px; }
  license-manager-simple fieldset.licenseManagerFieldset .validationErrorImage.form-control-feedback.fineprint,
  license-manager fieldset.licenseManagerFieldset .validationErrorImage.form-control-feedback.fineprint { position: absolute;
    background-image: url(/assets/icons/error_notification.svg); width: 16px; height: 16px; background-repeat: no-repeat;
    background-position: center center; background-size: contain; display: inline-block; top: 9px; right: 35px; z-index: 0; }
  license-manager-simple fieldset.licenseManagerFieldset .validationErrorImage.form-control-feedback.fineprint { top: 17px; }
  div.editFormContainer form.editConfigForm div.section2InputContainer license-manager .btn.toggleDetails { padding-left: 7px; }

  stack-rollback-choice input[type="checkbox"],
  stack-rollback-choice > label,
  stack-rollback-choice .labelContainer { position: relative; width: 30%; height: 100%; background-color: #fff; vertical-align: top; display: inline-block; }
  stack-rollback-choice div.labelContainer > label { position: absolute; top: 0px; right: 0px; color: #333;
    font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; display: inline-block;
    padding: 0px 17px 0px 0px; text-align: right; }

/* network-access */

network-access .section2InputValidationContainer {
  flex-grow: 1;
  position: relative;
  padding: 0px 5px;
}

.editFormContainer network-access .section2InputValidationContainer {
  padding: 0px;
}

network-access .section2InputValidationContainer select,
network-access .section2InputValidationContainer input {
  width: 100%;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 3px 10px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
}

network-access {
  display: flex;
  flex-direction: row;
}

network-access .labelContainer {
  text-align: right;
}

network-access .labelContainer {
  width: 30%
}

network-access div.labelContainer>label {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  padding: 0px 20px 0px 0px;
  text-align: right;
}

network-access div.labelContainer>label {
  position: relative;
}

network-access a.btn.btn_color_blue.companion_btn.toggleDetails {
  vertical-align: top;
  font-size: 20px;
  padding: 0px 2px;
  margin-left: 10px;
  border: none !important;
  color: #0076a8 !important;
  background: transparent !important;
}

div.editFormContainer network-access a.btn.btn_color_blue.companion_btn.toggleDetails {
  vertical-align: top;
  font-size: 20px;
  padding: 0px 12px;
  border: none !important;
  color: #0076a8 !important;
  background: transparent !important;
  margin-left: 4px;
}


network-access fieldset.networkAccessFieldset {
  flex-grow: 1;
  padding-right: 32px;
  width: 69%;
}

network-access fieldset.networkAccessFieldset .inline_form_error_msg {
  margin: 0;
}

network-access fieldset.networkAccessFieldset .inline_form_error_arrow {
  margin-left: 20px;
}

network-access fieldset.networkAccessFieldset .validationErrorImage.form-control-feedback.fineprint {
  position: absolute;
  background-image: url(/assets/icons/error_notification.svg);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  top: 9px;
  right: 35px;
  z-index: 0;
}

network-access .networkAccessInputContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 20px;
}

network-access .networkAccessInputContainer .labelContainer {
  min-height: unset;
  width: 60px;
  text-align: right;
}

network-access .networkAccessInputContainer .labelContainer label {
  padding: 0px 20px 0px 0px;
  position: relative;
}

network-access .networkAccessInputContainer select,
network-access .networkAccessInputContainer input {
  flex-grow: 1;
}

network-access .networkAccessInputContainer select.disabled,
network-access .networkAccessInputContainer input.disabled {
  opacity: 0.45;
  cursor: not-allowed;
}

.editFormContainer network-access>.labelContainer {
  margin: 0px;
  padding: 0px 20px 0px 0px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  text-align: right;
}

.editFormContainer network-access>.labelContainer label {
  padding: 0px;
}

.editFormContainer network-access fieldset.networkAccessFieldset {
  width: 60%;
  padding: 0px 20px 0px 0px;
}

/* mw-cluster-worker-picker */
  /* mw-cluster-worker-picker */
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .standardCheckboxLabelContainer { color: #333; font-family: Roboto, sans-serif; font-size: 13px; font-weight: normal; display: inline-block; padding: 0px; padding-left: 6px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .labelContainer { width: 30%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; display: inline-block; padding: 0px 20px 0px 0px; text-align: right; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .labelContainer label { color: #333; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer { padding: 8px 0px;  position: relative; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.machinesInClusterInputs div.inputContainer input[type="number"] { width: 100px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer:last-child { padding: 0px; padding-top: 8px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer { padding-left: 7px; padding-right: 0px; width: auto; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer.siblingAfterNote { width: auto; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset { border: none; padding: 0px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset input[type="number"]#maxWorkerNodes,
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset input[type="number"]#initialNumWorkersInCluster { cursor: not-allowed; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset input[type="number"]#numWorkersPerNode,
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset input[type="number"]#mw-numWorkersRequested { cursor:default; caret-color: white; user-select: none; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset input[type="number"]#numWorkersPerNode::selection,
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset input[type="number"]#mw-numWorkersRequested::selection { color: #000; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.fractionalMachineRequired { width: 100%; padding-left: 0px; padding-right: calc(10% + 5px); white-space: nowrap; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.fractionalMachineRequired .cautionIcon { vertical-align: middle; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.fractionalMachineRequired div.warningText { display: inline-block; opacity: 0.7; font-size: 12px; font-family: Arial, Helvetica, sans-serif; font-weight: normal; white-space: normal; padding: 8px; vertical-align: middle;}
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer input[type="checkbox"] { vertical-align: middle; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster { display: flex; flex-direction: column; padding-bottom: 10px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster legend { font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; text-align: right; width: 30%; padding: 0px 20px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts { display: flex; flex-direction: row; justify-content: left; margin-left: calc(30% + 15px); }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.machinesInClusterInputs { display: flex; flex-direction: column; justify-content: left; margin-left: calc(30% + 15px); }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.inputContainer { padding-right: 10px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.inputContainer:last-of-type { padding-left: 0px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts:first-of-type div.inputContainer:last-of-type { padding-left: 35px; position: relative; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster inline-validation { position: absolute; top: 30px; left: 0px; right: -120px; z-index: 100; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster .validationErrorImage.form-control-feedback.fineprint { top: -20px; right: 125px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.machinesInClusterInputs div.labelContainer,
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.labelContainer {display: block; text-align: left; width: auto; max-width: 150px; font-weight: normal; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.labelContainer.checkboxLabelContainer { display: inline-block !important; padding: 0px 8px 0px 0px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.machinesInClusterInputs div.section2InputValidationContainer:has(select) { padding: 0px; width: calc(100% - 80px); min-width: 100px; margin-right: 7px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer { padding: 0px; width: auto; min-width: 100px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer.siblingAfterNote input,
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer input { width: 100px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer:has(input[type="checkbox"]) { width: auto; min-width: auto; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer input[type="checkbox"] { width: auto; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer.siblingAfterNote { padding-right: 0px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer div.afterNote,
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.afterNote { display: inline-block; font-family: Roboto, sans-serif; font-size: 13px; color: #333; padding: 9px 0px 0px 6px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.afterNote.costWarning {color: #000; font-size: 12px; padding: 0px 35px 10px 15px; font-weight: 500; }
  mw-cluster-worker-picker.disabled { opacity: 1.0; cursor: not-allowed; }
  mw-cluster-worker-picker.disabled div.section2InputValidationContainer input, mw-cluster-worker-picker.disabled div.section2InputValidationContainer select { opacity: 0.45; cursor: not-allowed !important; }
  mw-cluster-worker-picker.disabled div.section2InputValidationContainer input[readOnly] { opacity: 1.0; }
  mw-cluster-worker-picker.disabled fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer input[type="checkbox"] { opacity: 1.0; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer:has(mw-cluster-worker-picker) { padding: 0px; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer:has(mw-cluster-worker-picker) + div.section2InputContainer { padding: 3px 0px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer + sub { display: block; padding: 2px 8px; margin-bottom: 20px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer div.section2InputValidationContainer + sub { position: absolute; left: 35px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.machinesInClusterInputs div.inputContainer div.section2InputValidationContainer + sub { position: absolute; left: 8px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer.withSub + div.inputContainer { margin-top: 15px; }
  mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.workersInClusterCounts:has(div.inputContainer.withSub) + div.workersInClusterCounts { margin-top: 15px; }

/* editable-text */
editable-text button.btn.btn_color_blue.companion_btn.btn-sm.icon-attribute {
  padding: 5px 4px 4px 5px;
  border: none;
}

editable-text div.editableTextElementContainer {
  padding: 0px 3px;
}

editable-text div.editableTextElementContainer div.linkContainer button {
  padding: 0px;
  margin: 0px;
}

editable-text div.editableTextElementContainer div.linkContainer button>div {
  margin: 0px;
  padding: 0px;
  background-size: 80%;
  vertical-align: middle;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

editable-text div.editableTextElementContainer div.editNameContainer form {
  width: 100%;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form div.buttonContainer {
  display: block;
  white-space: normal;
  margin: 0px;
  padding: 3px 0px 0px 0px;
  text-align: right;
  width: 100%;
  line-height: 14px;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form div.buttonContainer>button {
  display: table-cell;
  padding: 1px;
  margin: 0px;
  position: relative;
  top: 2px;
  outline-offset: 0;
  height: 16px;
  width: 17px;
  border: 1px solid #6f6f6f;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form div.buttonContainer>button.saveBtn {
  margin-right: 5px;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form div.buttonContainer>button:hover {
  border-color: #0076A8 !important;
  background-color: #ccc;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form div.buttonContainer>button>div {
  margin: 0px;
  padding: 0px;
  background-size: 80%;
  vertical-align: middle;
}

editable-text div.editableTextElementContainer div.editNameContainer form div.section2InputValidationContainer {
  width: 100%;
  padding: 0px;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form div.section2InputValidationContainer {
  width: 100%;
  padding: 0px;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form input {
  width: 100%;
  min-width: 210px;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form input {
  margin-top: 2px;
  line-height: 16px;
  height: 20px;
}

editable-text div.editableTextElementContainer div.linkContainer>div.enPlainText {
  display: inline-block;
  padding: 0px;
  margin: 0px;
}

editable-text div.editableTextElementContainer div.editNameContainer .validationErrorImage.form-control-feedback.fineprint {
  top: -24px;
  right: 15px;
}

editable-text div.editableTextElementContainer div.editNameContainer.usingButtons .validationErrorImage.form-control-feedback.fineprint {
  top: -18px;
  right: 15px;
}

editable-text div.editableTextElementContainer .inline_form_error_msg {
  margin: 0px;
}

editable-text div.editableTextElementContainer .inline_form_error {
  position: absolute;
  left: 0;
  right: 0;
}

editable-text input[type="text"].validationError {
  border-color: #b7312c !important;
}

.attribute.resource-machine-name.editableText {
  overflow: visible;
}

.attribute.attribute-credential-page.editableText {
  padding: 0px;
  overflow: visible;
}

a.goToResourceDetail {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

/* ssl-certificate */

ssl-certificate {
  position: relative;
  display: inline-block;
  width: 100%;
}

ssl-certificate .section2InputValidationContainer {
  left: -5px;
}

ssl-certificate .labelContainer {
  position: relative;
  width: 30%;
  height: 100%;
  text-align: right;
  background-color: #fff;
  vertical-align: top;
  display: inline-block;
}

ssl-certificate .labelContainer button {
  border: none;
  background-color: inherit;
  color: #0076a8;
  text-decoration: none;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
}

ssl-certificate span.buttonSpacer {
  margin-right: 20px;
}

ssl-certificate div.labelContainer>label {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: block;
  padding: 0px 20px 0px 0px;
  text-align: right;
}

ssl-certificate fieldset.sslCertificateChooserFieldset {
  display: inline-block;
  width: 69%;
}

ssl-certificate .section2InputValidationContainer {
  position: relative;
  width: calc(100% - 65px);
}

ssl-certificate .section2InputValidationContainer select {
  width: 100%;
}

ssl-certificate inline-popover a.btn.toggleDetails {
  margin-left: 5px !important;
}

.editFormContainer ssl-certificate inline-popover a.btn.toggleDetails {
  margin-left: 0px !important;
  padding: 0px 10px !important;
}


ssl-certificate button.btn.customElementCreateNewButton {
  padding: 3px 1px;
}

.hiddenLabel {
  display: none;
}

/* create-new-ssl-cert-dialog */

div.createSSLCertContainer {
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  display: block;
}

div.createSSLCertContainer p {
  font-size: 14px;
}

div.createSSLCertContainer p a {
  color: #0076a8;
  text-decoration: none;
}

div.createSSLCertContainer p a:visited {
  color: #004b87;
  text-decoration: none;
}

div.createSSLCertContainer p a:hover {
  color: #004b87;
  cursor: pointer;
  text-decoration: underline;
}

div.createSSLCertContainer p a:focus,
button:focus,
.btn:focus {
  outline: 2px solid #268cdd !important;
  outline-offset: 2px;
}

div.createSSLCertContainer div.section2InputValidationContainer {
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 10px;
}

div.createSSLCertContainer div.section2InputValidationContainer label {
  display: inline-block;
  padding-right: 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

div.createSSLCertContainer div.section2InputValidationContainer input {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 3px 10px;
  color: #000;
  font-size: 15px;
  border: 1px solid #808080;
  border-radius: 4px;
  margin-top: 3px;
}

create-new-ssl-cert-dialog inline-validation div.inline_form_error_arrow {
  position: relative;
  left: 60px;
}

create-new-ssl-cert-dialog inline-validation div.inline_form_error_msg.sslNameInput {
  position: relative;
  left: 60px;
}

create-new-ssl-cert-dialog inline-validation div.validationErrorImage.form-control-feedback.fineprint {
  right: 45px;
  top: 30px;
}

create-new-ssl-cert-dialog mw-progress-indicator .progressIndicatorSvg {
  position: static;
}

/* machine-type-chooser-with-finder */

machine-type-chooser-with-finder div.sectionContents fieldset.section2Group {
  margin-top: 0px;
}

h2.machineTypeChooserHeader {
  margin: 0px 0px 0px 0px;
  padding-left: 100px;
  color: #0076a8;
}

machine-type-chooser-with-finder div.labelContainer label {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  flex-wrap: nowrap;
  vertical-align: bottom;
}

machine-type-chooser-with-finder div.radioButtonContainer div.col-12 {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 0px 0px;
  padding-left: 100px
}

machine-type-chooser-with-finder div.radioButtonContainer div.col-12 input[type="radio"] {
  margin-right: 15px;
}

machine-type-chooser-with-finder button {
  margin-top: 6px
}

machine-type-chooser-with-finder .section2Group .buttonContainer {
  width: 100%;
  padding: 0px 75px;
}

machine-type-chooser-with-finder .editChoicesButtonContainer {
  padding-left: 105px;
  position: relative;
  top: 12px;
}

machine-type-chooser-with-finder .editChoicesButtonContainer button {
  font-size: 13px;
}

machine-type-chooser-with-finder .hidden {
  display: none;
}

machine-type-chooser-with-finder div.section2InputValidationContainer {
  padding-left: 5px;
  padding-right: 11px;
}

machine-type-chooser-with-finder fieldset.section2Group > div.section2InputContainer > label {
  vertical-align: sub;
}

machine-type-chooser-with-finder div.section2InputValidationContainer input[type="number"],
machine-type-chooser-with-finder div.section2InputValidationContainer input[type="password"],
machine-type-chooser-with-finder div.section2InputValidationContainer input[type="text"],
machine-type-chooser-with-finder div.section2InputValidationContainer select {
  margin: 0px;
}

/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

/* Release requires license warning */
div.releaseLicenseWarningContainer.autoComplete {
  display: none;
}

div.releaseLicenseWarningContainer {
  display: block;
  width: 100%;
  margin: 20px 0px;
  padding: 10px 0px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: #333;
  border: 1px solid #FFD60A;
  border-radius: 0.5px;
  max-width: 759px;
  background-color: #FEF9ED;
}

div.releaseLicenseWarningContainer>span.horizontalIconContainer>div.cautionIcon {
  position: absolute;
  display: inline-block;
  margin: 0px 2px 0px 10px;
  vertical-align: text-bottom;
  ;
}

div.releaseLicenseWarningContainer>span.horizontalIconContainer>div.releaseLicenseWarningText {
  display: inline-block;
  padding-left: 40px;
}

div.wizardSummary>div.releaseLicenseWarningContainer {
  margin: 0px 0px 20px 0px;
}

/* Alert Icons */
.notificationImageInfo,
.notificationImageError,
.notificationImageSuccess {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
}

.notificationImageInfo {
  background-image: url(../icons/info_16.svg);
}

.notificationImageError {
  background-image: url(../icons/error_notification.svg);
}

.notificationImageSuccess {
  background-image: url(../icons/success_notification.svg);
}

/* Global Alerts */
.importantMessage,
.importantMessageInfo,
.importantMessageWarning,
.importantMessageError {
  width: 50%;
  font-size: 15px;
  position: absolute;
  top: 52px;
  left: max(350px, calc(50% - 25%));
  z-index: 4;
}

/* dialogs */
.warningIcon {
  background-image: url(../icons/error_notification.svg);
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  vertical-align: top;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.warningDialogText {
  display: inline-block;
  padding: 2px 0px 0px 10px;
}

/* Login Error (Home Page) */
.loginErrorWrapper {
  max-width: 450px;
  padding: 10px 10px 10px 36px;
  margin: 0 15px;
  background-color: #fae8e6;
  color: #a31b20;
}

.loginErrorWrapper .notificationImageError {
  margin: 2px 0 0 -26px;
  display: block;
  float: left;
}

.loginErrorWrapper #loginHandshakeErrorText {
  margin-bottom: 0;
  font-weight: bold;
}

/*
    Modal Alerts
  */

/* Modal Alerts: New Folder, sharing */
.importantDialogError {
  padding: 10px 10px 10px 36px;
  background-color: #fae8e6;
  color: #a31b20;
  font-size: 15px;
}

.importantDialogSuccess {
  padding: 10px 10px 10px 36px;
  background-color: #eefae6;
  color: #3a913f;
  font-size: 15px;
}

.importantDialogInfo {
  padding: 10px 10px 10px 36px;
  background-color: #e6f2fa;
  color: #0076A8;
  font-size: 15px;
}

/* Global Alert Bar */
[id^="ccwaNotification"] {
  width: 100%;
  display: table;
}

[id^="ccwaNotification"]>[class*="notificationContent"] {
  height: 45px;
  padding-right: 36px;
  padding-left: 36px;
  display: table-cell;
  vertical-align: middle;
}

[id^="ccwaNotification"] [class*="notificationContent"] [class*="notificationImage"] {
  margin: 1px 0 0 -26px;
  display: block;
  float: left;
}

.notificationWrapper {
  background-color: #eefae6;
  color: #3a913f;
}

.notificationInfoWrapper {
  background-color: #e6f2fa;
  color: #0076A8;
}

.notificationErrorWrapper {
  background-color: #fae8e6;
  color: #a31b20;
}

.notificationContent {
  color: #3a913f;
}

.notificationContentError {
  color: #a31b20;
}

.notificationContentInfo {
  color: #0076A8;
}

.notificationContentLink {
  color: #0076a8;
}

.notificationContentText {
  color: #3a913f;
}

.notificationContentTextInfo {
  color: #0076A8;
}

.notificationContentTextError {
  color: #a31b20;
}

.notificationAckButton,
.notificationAckButtonError,
.notificationAckButtonInfo {
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: 10px;
}

.notificationAckButton>span.glyphicon-remove,
.notificationAckButtonInfo>span.glyphicon-remove,
.notificationAckButtonError>span.glyphicon-remove {
  color: #595959;
  cursor: pointer;
  font-size: 16px;
  opacity: 0.5;
}

.notificationAckButton>span.glyphicon-remove:hover,
.notificationAckButtonInfo>span.glyphicon-remove:hover,
.notificationAckButtonError>span.glyphicon-remove:hover {
  cursor: pointer;
  opacity: 1.0;
}

.progressContainer {
  display: none;
}

.progressStatus.progressStaticText {
  height: 20px;
  min-height: 20px;
}

.progressSeparator {
  height: 40px;
}

.progressStaticText {
  padding-bottom: 5px;
  color: #0076a8;
  font-size: 14px;
}

/* ---- Modals ---- */
.modal-title {
  font: normal 400 18px/1.20 "Roboto", Arial, Helvetica, sans-serif;
  padding-right: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}

.modal-body {
  word-wrap: break-word;
}

.ccwa-modal-label {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

/* Modal Alignment -- Change vertical-aligment to :middle */
.modal {
  text-align: center;
}

.modal:before {
  height: 100%;
  margin-right: -4px;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

/* Modal Buttons -- site6 Classes */
.modal-dialog .btn {
  padding: 10px 14px 9px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  line-height: 1.00;
  white-space: normal;
}

.modal-dialog .btn:hover,
.modal-dialog .btn:focus,
.modal-dialog .btn:active {
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.2);
}

.btn-primary,
.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-primary:visited {
  background: #0076a8;
  border: 1px solid #0076a8;
  border-radius: 5px;
  color: #fff !important;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary.active.focus {
  outline-offset: -3px;
}

.modal-dialog .btn-default {
  padding: 9px 13px 8px;
  border-style: solid;
  border-width: 1px;
  background: transparent !important;
}

.modal-dialog .statusIconContainer.showDeleteCollaboratorConfirmation.btn.btn-default,
.modal-dialog button.statusIconContainer.deleteInvitee.btn {
  padding: 3px;
  position: absolute;
  top: 5px;
  border: none;
}

.modal-dialog .btn-default,
.modal-dialog .btn-default:visited {
  border-color: #0076a8;
  color: #0076a8 !important;
}

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */
/* embedded widget general */
.embeddedWidget {
  background: white;
  color: black;
}

.embeddedWidget .col-xs-12 {
  padding-left: 0px;
  padding-right: 0px;
}

div.embeddedWidget .tooltip {
  position: fixed;
}

/* embedded widget tester stylesheet */
div#widgetTesterPage div#widgetTestChoiceContainer p.normal {
  color: black;
  font-weight: normal;
}

div#widgetTesterPage div#widgetTestChoiceContainer p.noCredentials {
  color: red;
  font-weight: bold;
}

div#widgetTesterPage div#widgetTestChoiceContainer p.credentialsUnknown {
  color: black;
  font-weight: bold;
}

div#widgetTesterPage .active {
  display: block;
}

div#widgetTesterPage .inactive {
  visibility: hidden;
  width: 0px;
  height: 0px;
  border: none;
}

div#widgetTesterPage .initialIframe {
  border: none;
  height: 1px;
  width: 1px;
  margin: 0px;
  padding: 0px;
}

div#widgetTesterPage .staticIframe {
  width: 600px;
  height: 500px;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
}

div#widgetTesterPage .responsiveIframe {
  width: auto;
  height: auto;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
}

div#widgetTesterPage div#saveToDriveDemoContainer .staticIframe {
  width: 600px;
  height: 500px;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
}

div#widgetTesterPage .links {
  width: 620px;
  height: 335px;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
}

div#widgetTesterPage .manage {
  width: 780px;
  height: 500px;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
}

div#widgetTesterPage div.floater .links {
  width: 100%;
  height: 100%;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
}

div#widgetTesterPage div.floater .manage {
  width: 100%;
  height: 100%;
  border: none;
  background: white;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
}

div#widgetTesterPage .btn {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: #0076A8;
  color: #fff;
  margin: 0px 5px;
}

div#widgetTesterPage .buttonContainer {
  margin-top: 20px;
  padding: 10px;
}

div#widgetTesterPage div.floater {
  position: fixed;
  z-index: 100;
  top: calc(50vh - 250px);
  left: calc(50vw - 365px);
  border-radius: 5px;
  background-color: #555555;
  border-color: #555555;
}

div#widgetTesterPage .waitingContents {
  display: none;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

div#widgetTesterPage div.waiting>div.waitingContents {
  display: block;
}

div#widgetTesterPage input#filePathInput,
div#widgetTesterPage input#contentsURLInput {
  width: 500px;
  margin-bottom: 10px;
}

div#widgetTesterPage form#widgetChoiceForm hr {
  height: 1px;
  background-color: #ddd;
}

div#widgetTesterPage div#widgetTestChoiceContainer {
  display: block;
  float: left;
  clear: none;
  margin-right: 50px;
}

div#widgetTesterPage div#widgetTestChoiceContainer ul#widgetChoiceList {
  padding-left: 20px;
  list-style: none;
  width: 400px;
  height: 225px;
  max-height: 225px;
  overflow-y: auto;
  border: solid 1px #ddd;
}

div#widgetTesterPage div#widgetTestChoiceContainer ul#widgetChoiceList>li {
  padding: 2px 10px 2px 5px;
  list-style-type: none;
  position: relative;
}

div#widgetTesterPage div#widgetTestChoiceContainer ul#widgetChoiceList>li label {
  padding-left: 10px;
}

div#widgetTesterPage div#cssCheckboxContainer label,
div#cssOverrideContainer label {
  padding-left: 10px;
}

div#widgetTesterPage div#desktopStyleChoiceRadioContainer label,
div#iframeDimensionsContainer label {
  padding-left: 0px;
  padding-right: 10px;
}

div#widgetTesterPage div#cssOverrideContainer div#cssOverrideUrlContainer,
div#widgetTesterPage div#cssCheckboxContainer div#desktopStyleChoiceRadioContainer,
div#widgetTesterPage div#ignoreResizeContainer div#iframeDimensionsContainer {
  padding-left: 35px;
}

div#widgetTesterPage div#cssCheckboxContainer {
  padding-bottom: 20px;
}

div#widgetTesterPage div#widgetTesterPage div#widgetTestChoiceContainer button#resetButton {
  margin-left: 10px;
}

div#widgetTesterPage div#widgetChoiceButtonContainer {
  margin-bottom: 20px;
  padding: 10px 0px;
}

div#widgetTesterPage div#widgetTestOutputContainer {
  display: block;
  float: left;
  clear: none;
}

div#widgetTesterPage div#widgetTestOutputContainer>div#selectedWidgetContainer {
  border-top: solid 1px #ddd;
}

div#widgetTesterPage div#widgetTestOutputContainer h1,
div#widgetTestChoiceContainer h1 {
  font-size: 24px;
  font-weight: 700;
}

div#widgetTesterPage div#widgetTestOutputContainer h3,
div#widgetTestChoiceContainer h3 {
  font-size: 16px;
  font-weight: 700;
}

div#widgetTesterPage div#widgetTestChoiceContainer div#cssOverrideContainer {
  margin-bottom: 20px;
}

div#widgetTesterPage div#widgetTestOutputContainer {
  display: none;
}

div#widgetTesterPage div#widgetTestOutputContainer div#selectedWidgetContainer div.widgetOptions {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

div#widgetTesterPage div#widgetTestOutputContainer div#selectedWidgetContainer div.widgetOptions input[type=checkbox] {
  margin-right: 5px;
}

div#widgetTesterPage div#widgetTesterPage div#widgetTestOutputContainer div#selectedWidgetContainer div.widgetOptions input[type=text] {
  margin-left: 5px;
}

div#widgetTesterPage div#widgetTestOutputContainer div#selectedWidgetContainer div.widgetOptions input[type=number] {
  margin-left: 5px;
  width: 60px;
}

div#widgetTesterPage button#resetWidgetOptionsButton {
  margin: 10px 3px;
}

div#widgetTesterPage iframe.fixedSize,
div#widgetTesterPage iframe.links.active.fixedSize,
div#widgetTesterPage iframe.manage.active.fixedSize {
  border: dashed 2px #ff0000;
}

div#widgetTesterPage div.embeddedWidgetResultContainer {
  margin: 15px 0px;
  padding: 10px;
  border-bottom: solid 1px #ddd;
  border-top: solid 1px #ddd;
}

div#widgetTesterPage div.embeddedWidgetResultContainer label {
  display: block;
}

div#widgetTesterPage div.embeddedWidgetResultContainer p {
  width: 100%;
  padding: 5px;
}

div#widgetTesterPage div#uiOptionsContainer {
  padding-left: 20px;
}

div#widgetTesterPage div.widgetOptionsButtonContainer {
  padding-top: 5px;
  margin-top: 10px;
  border-top: solid 1px #ddd;
}

div#widgetTesterPage div#widgetTestOutputContainer div.widgetOptions table td {
  padding: 5px;
}

div#widgetTesterPage div#widgetTestOutputContainer div.widgetOptions table td div label {
  padding-right: 5px;
}

div#widgetTesterPage div#widgetTestOutputContainer div.widgetOptions table td div input[type=text] {
  width: 400px;
}

div#widgetTesterPage div.testiframe-backdrop {
  z-index: auto;
}

autologin-licensemanager select.disabled {
  cursor: not-allowed;
  opacity: .45;
}

autologin-licensemanager fieldset.section2Group>div.section2InputContainer.allmCont {
  padding: 0px;
}

autologin-licensemanager fieldset.section2Group.allmGroup {
  margin-top: 0px;
}

autologin-licensemanager .section2Group>.section2InputContainer .section2InputContainer {
  margin-top: 0px;
  margin-bottom: 0px;
}

autologin-licensemanager div.licenseManagerOptionSelectors {
  position: relative;
  display: inline-block;
  width: 66%;
  vertical-align: top;
}

autologin-licensemanager div#selectEntitlementID {
  padding-bottom: 16px;
}

autologin-licensemanager div.licenseManagerOptionSelectors div.section2InputValidationContainer {
  width: calc(100% - 50px);
  display: inline-block;
  padding-left: 0px;
}