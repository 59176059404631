/*
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */

/* ------------------------------------
$ Page Globals
------------------------------------ */

  div.computeContainer,
  div.awsImportRoleContainer,
  div.awsCreateRoleContainer,
  div.awsLegacyParallelContainer,
  div.credentialsContainer { padding: 5px 10px;}

/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */

  #matrix_collapse { width:calc(100% + 30px); margin-right:-15px; margin-left:-15px; background:#fff; }

/* ------------------------------------
Structure
------------------------------------ */

  /* ---- Header ---- */

  /* Header: Global Navbars */
  .navbar-header { text-align: center; }
  .navbar-brand { height:44px; padding:0px; }
  .navbar-brand .mw_logo { width:157px; height:32px; margin:6px 0px 0px 5px; }
  .navbar-brand .mlc_logo { width:245px; height:32px; margin:6px 0px 0px 5px; }
  .navbar-toggle { float: left; clear: none; padding:0px; margin-top:2px; margin-right:4px; margin-bottom:2px; border:none; top: 5px; }
  .navbar-toggle { margin-right: 0; } /* Fix for 3 icons*/
  .navbar-toggle.subnav { display: none !important; }
  .navbar-toggle:hover,
  .navbar-toggle:focus { background-color: #fff; border-radius: 0; }
  .navbar-toggler .icon-menu { width: 46px; height: 39px; background: url(../icons/menu.svg) no-repeat center center; background-size: 44px; display: block !important; padding-top: 3px;}
  .navbar-collapse li { font-size:14px; line-height: 1.00; width: 100%; }
  .navbar-nav { margin-top: 0; margin-bottom: 0; }
  #topnav { margin-top: 0; border-top: 1px solid #e6e6e6; }
  #topnav.navbar-nav > li > a { padding: 11px 15px; font-weight: normal; display: inline-block; width: 100%; }
  #topnav.navbar-nav > li > a,
  #topnav.navbar-nav > li > a:focus,
  #topnav.navbar-nav > li > a:visited { color: #333; }
  #topnav.navbar-nav > li > a:hover { color: #333; background: #e6ebef; }
  #topnav.navbar-nav > li.active > a,
  #topnav.navbar-nav > li.active > a:hover { border-radius: 0; color: #333; background: #e6ebef; }
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:hover,
  .navbar-nav > .open > a:focus { color: #333; background: #e6ebef; }
  .navbar-nav > .open .dropdown-menu { padding: 0; border-top: 1px solid #e6e6e6; }
  .navbar-nav > .open .dropdown-menu > li > a { padding: 11px 15px 11px 25px; color: #333; }
  .navbar-nav > .open .dropdown-menu > li > a:hover,
  .navbar-nav > .open .dropdown-menu > li > a:focus { color: #333; background: #e6ebef; }

  /* Header: Mobile Subnav */
  #mobile_subnav { background: #0076A8; }
  #mobile_subnav .navbar-collapse { max-height: none; border-top: 1px solid #fff; border-top: 1px solid rgba(255,255,255,0.5); }
  #mobile_subnav .navbar-collapse .subnav { margin-bottom: 0; }
  #mobile_subnav .navbar-collapse .subnav > li { color: #fff; }
  #mobile_subnav .navbar-collapse .subnav > li + li { border-top: 1px solid #fff; border-top: 1px solid rgba(255,255,255,0.5); }
  #mobile_subnav .navbar-collapse .subnav > li .caret { margin: 0 0 0 5px; }
  /*#mobile_subnav .navbar-collapse .subnav > li > a { padding: 11px 18px 11px 8px; }*/
  #mobile_subnav .navbar-collapse .subnav > li { padding: 11px 18px 11px 8px; } /* Drive Specfic */
  #mobile_subnav .navbar-collapse .subnav > li > a { color: #fff; }
  #mobile_subnav .navbar-collapse .subnav > li > a:hover,
  #mobile_subnav .navbar-collapse .subnav > li > a:focus,
  #mobile_subnav .navbar-collapse .subnav > li > a:active { background: #235e85; background: rgba(0,0,0,0.2); color: #fff; }
  #mobile_subnav .navbar-collapse .subnav > li:hover,
  #mobile_subnav .navbar-collapse .subnav > li:focus,
  #mobile_subnav .navbar-collapse .subnav > li:active,
  #mobile_subnav .navbar-collapse .subnav > li.active { background: #235e85; background: rgba(0,0,0,0.2); color: #fff; cursor: pointer; } /* Drive Specific */
  #mobile_subnav .navbar-collapse .open > a,
  #mobile_subnav .navbar-collapse .open > a:hover,
  #mobile_subnav .navbar-collapse .open > a:focus,
  #mobile_subnav .navbar-collapse .open > a:active { background: #235e85; background: rgba(0,0,0,0.2); color: #fff; }
  #mobile_subnav .navbar-collapse .dropdown-menu { padding-top: 0; padding-bottom: 0; border-top: 1px solid #fff; border-top: 1px solid rgba(255,255,255,0.5); background: #0076A8; }
  #mobile_subnav .navbar-collapse .dropdown-menu > li > a { padding: 11px 18px; white-space: normal;  }
  #mobile_subnav .navbar-collapse .dropdown-menu > li > a { color: #fff; }
  #mobile_subnav .navbar-collapse .dropdown-menu > li > a:hover,
  #mobile_subnav .navbar-collapse .dropdown-menu > li > a:focus,
  #mobile_subnav .navbar-collapse .dropdown-menu > li > a:active { background: #235e85; background: rgba(0,0,0,0.2); color: #fff; }
  #mobile_subnav .navbar-collapse .dropdown-menu > .active > a,
  #mobile_subnav .navbar-collapse .dropdown-menu > .active > a:hover,
  #mobile_subnav .navbar-collapse .dropdown-menu > .active > a:focus { background: #235e85; background: rgba(0,0,0,0.2); color: #fff; }

  #topnav_collapse li.headernav_help > a { width:100%; text-align:left; border-bottom: solid 1px #e7e7e7; }
  #topnav_collapse li.headernav_help .horizontalIconContainer { display: flex; justify-content: flex-start; align-items: center; }
  div#header_desktop { padding: 0px 0px; }
  div#header_mobile.header.blockContainer { height: 55px; }
  div#header_mobile.header.blockContainer button.navbar-toggle.topnav_toggle.collapsed { display: inline-block; }
  div#header_mobile .col-xs-12 { padding: 0px 5px; }
  div#header_mobile .matrix_container { right: 0px !important; }
  #topnav_collapse { max-height: none; margin-right: -5px; margin-left: -5px; background: #fff; border-top: none; }
  #topnav_collapse li + li { border-top: 1px solid #e6e6e6; }
  #header_mobile .navbar-toggle:hover,
  #header_mobile .navbar-toggle:focus { background-color: #0076A8; border-radius: 0; }
  #header_mobile .navbar-header { height: 53px; }
  /* Login page */
  #loginContainer .topnav_toggle { visibility: hidden; display: none; }
  div.loginContainer div.navbar-brand { padding-left: 0px;}
  div.loginContainer .container-fluid { justify-content: center; }

  /* Matrix */
  div#matrix_affordance_container_mobile { float: right; clear: none; }
  div#matrix_collapse.collapse.show { border-bottom: 1px solid #ddd; }

  /* CloudCenter Login/Info Dropdown */
  #cloud_center_nav_mobile { margin: 0px; padding: 0px; }
  #cloud_center_nav_mobile li + li { border-top: none; }
  #cloud_center_nav_mobile > li > a { padding: 10px 0 10px 25px; white-space: normal; line-height: 20px; }

  [id*="cloud_center_nav"] > .quotaListItem,
  [id*="cloud_center_nav"] > .dropdown-email,
  [id*="cloud_center_nav"] > .dropdown-showhiddenfiles,
  [id*="cloud_center_nav"] > .dropdown-additionalApplications,
  [id*="cloud_center_nav"] > .dropdown-username { padding-right: 15px; padding-left: 15px; }
  [id*="cloud_center_nav"] > .dropdown-showhiddenfiles .checkbox-showhiddenfiles { margin: 6px 0;}
  [id*="cloud_center_nav"] > .dropdown-additionalApplications .radio-additionalApplications { margin: 6px 10px;}
  [id*="cloud_center_nav"] > .dropdown-showhiddenfiles > label {font-weight: normal;}
  [id*="cloud_center_nav"] > .dropdown-additionalApplications label {font-weight: normal;}
  [id*="cloud_center_nav"] > .dropdown-additionalApplications > label {margin: 6px 0;}

  /* ---- Section Header ----  */
  .section_header_content { padding-right: 19px; }
  .has_horizontal_local_nav#section_header_title:after { width: 0; height: auto; border-right: 1px solid #fff; border-right: 1px solid rgba(255,255,255,0.25); bottom: 0; content: ""; position: absolute; right: 0; top: 0; }

/* ------------------------------------
$ Site Elements
------------------------------------ */

  .appTitle { display: inline-block; }
  .actionIconContainer { position: static; padding: 10px 15px; }
  .icon-arrow-down {
    width: 26px; height: 26px; background: url(../icons/arrow-down.svg) no-repeat center center; background-size: 24px; display: block;
  }
  .icon-attribute::after {
    content: attr(data-name); white-space: nowrap; font-family: Roboto, sans-serif;
    font-size: 14px; position: relative; top: 2px;
  }
  .icon-attribute { background-color: inherit; border: none; }
  .icon-attribute div { vertical-align: middle; }
  .cred-dropdown-mobile { background-color: white !important; }
  .li-about-page:hover,
  .li-management-page:hover,
  .li-cred-page:hover { background-color: #f6f6f6; }
  .cred-type-menu-options { width: 100%; text-align: left; font-size: 14px; color: #333; }
  .threedot-about-page-mobile,
  .threedot-management-page-mobile,
  .threedot-credential-page-mobile { margin-top: 0px; }
  .hr-margin{ margin-top: 10px; margin-bottom: 10px; }

  .facetBar { position: static; padding: 0px 0px 5px 0px; border-bottom: solid 1px #bfbfbf; background-color: #fff;}
  div.facetBar > ul { padding-top: 20px;}

  .dropdown-menu { width: 100%; width: 100vw; padding: 5px 5px 5px 5px; position: fixed; top: 0; left: 0; }
  ul.dropdown-menu li h2 { padding: 3px 15px; }
  ul.dropdown-menu.credential-action-dropdown-menu li:hover,
  ul.dropdown-menu.resource-action-dropdown-menu li:hover,
  ul.dropdown-menu.credential-action-dropdown-menu li:active,
  ul.dropdown-menu.resource-action-dropdown-menu li:active { background-color: #f6f6f6; }
  div.dropdown.menu-container.resource-menu.context-menu.attribute ul.dropdown-menu {
    background-color: transparent; height: min-content; padding: 0px 0px 20px 0px;
  }
  ul.dropdown-menu > div.menuContent { background-color: white; padding: 0px 0px 20px 0px; }
  ul.dropdown-menu > div.mobileCover { height: 100%; background-color: transparent; }

  li.resource-menu-mobile.hasButtons { font-weight: 700; }
  .menu-container ul.dropdown-menu li button {
    font-family: Roboto, sans-serif; white-space: nowrap; color: #212121 !important; font-size: 14px; text-align: left; width: 100%;
  }
  li.item.item-container,
  li.item.item-container.item-container-for-about-page,
  li.item.item-container.item-container-for-management-page,
  li.item.item-container.item-container-for-cred-page { position: relative; }
  li.item-container-for-about-page .dropdown:after,
  li.item-container-for-about-page .dropdown.open .resource-nav,
  li.item-container-for-about-page .dropdown.open:after,
  li.item-container-for-management-page .dropdown:after,
  li.item-container-for-management-page .dropdown.open .resource-nav,
  li.item-container-for-management-page .dropdown.open:after,
  li.item-container-for-cred-page .dropdown:after,
  li.item-container-for-cred-page .dropdown.open .resource-nav,
  li.item-container-for-cred-page .dropdown.open:after,
  section > ol.collection-container li.item-container .dropdown:after {
    content: ''; top: 0; right: 0; bottom: 0; left: 0;
    transition: opacity .15s ease-in-out; opacity: 0; visibility:hidden;
    background-color: rgba(0,0,0,.7); pointer-events: none; z-index:10;
  }
  section > ol.collection-container li.item-container .dropdown.open .resource-nav { position:relative; z-index:12; }
  li.item-container-for-about-page .dropdown.open:after,
  li.item-container-for-management-page .dropdown.open:after,
  li.item-container-for-cred-page .dropdown.open:after,
  section > ol.collection-container li.item-container .dropdown.open:after { z-index:10; opacity:1; visibility:visible; position: fixed; }

  .resource-menu-mobile { display: inline-block; }
  li.resource-menu-mobile.hasButtons { position: relative; width: 100%; line-height: 36px; }
  li.resource-menu-mobile.hasButtons > div { display: inline-block; }
  li.resource-menu-mobile.hasButtons > div.textDiv { padding-left: 10px; }
  li.resource-menu-mobile.hasButtons > div.exitBtnDiv { position: absolute; right: 5px; }
  li.resource-menu-mobile.hasButtons > div.exitBtnDiv > button.btn {
    border: none; background-color: #fff; font-family: Roboto, sans-serif; font-weight: bold;
  }
  li.resource-menu-mobile.hasButtons > div.exitBtnDiv > button.btn:hover { color: #0076A8; }

  .collection-container { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; }
  .item { border-bottom: 1px solid #bfbfbf; padding: 10px; }
  /* Don't display the first item, since it is used to display the header for tabular layouts*/
  .collection-container > li:first-child { display: none; }

  .attribute.resource-status.status { display: block; }
  .attribute:not(.alt-mobile-header)::before {
    font-family: Roboto, sans-serif; font-weight: bold; color: #1a1a1a; font-size: 14px;
    content: attr(data-name); display: block; float: left;
  }
  .attribute.invisible-mobile-header:not(.alt-mobile-header)::before { color: transparent; }
  div.attribute { padding: 4px 0px; line-height: 18px; }
  div.attribute button { background-color: inherit; border: none; }
  div.attribute.menu-container { display: inline-block; padding: 8px 0px; position: absolute; right: 0px; top: 0px; }
  div.attribute.cta { display: inline-block; padding: 8px 0px; }
  div.attribute.menu-container > span,
  div.attribute.cta > span { display: inline-block; grid-column: unset; }
  div.attribute.menu-container  > span  > button.icon-attribute { color: #808080; padding-right: 0px; margin-right: 0px; }
  div.attribute.menu-container  > span  > button.icon-attribute div.threeDotsVerticalIcon.smallIcon { opacity: 1; }
  div.attribute.cta > span  > button.icon-attribute { border: none; margin: 0px; color: #0076A8;}
  div.attribute.cta > span:first-of-type  > button.icon-attribute { margin-left: 10px; }
  div.attribute.menu-container > span  > button.icon-attribute::after  { content: ""; position: relative; top: 2px;}
  div.attribute.cta > span  > button.icon-attribute::after { content: ""; position: relative; top: 2px;}
  div.attribute > span { color: #6f6f6f; font-weight: normal; }
  .attribute.alt-mobile-header::before { content: attr(data-name-mobile); min-height: 30px; }
  .attribute.hide-mobile-header::before { content: ""; }
  .attribute.hide-mobile-header { text-align: left; }
  /* Attribute name for first column, and attribute value for second column. */
  .attribute { display: grid; grid-template-columns: minmax(9em, 30%) 1fr; min-height: 16px; }
  div.attribute.statusAndCountdownContainer { height: auto; display: block; }
  div.attribute.resource-primary-cta > span { grid-column: 2; }

  div.resource-status { padding: 0px; margin: 0px; text-align: unset; color: white; height: 26px; }
  div.resource-status > div.progressbar { height: 100%; }

  /* Additional Details */
  div.additionalDetailContainer > div.generatedCode > div.accessWrapper > div.accessList > div.accessPortContainer > h3.subtitle > button.removeAccessButton.btn {position: static; margin-right: 0px; }
  div.additionalDetailContainer > div.generatedCode > div.accessWrapper > div.accessList > div.accessPortContainer > button.addAccessButton {margin-right: 0px; }
  div.additionalDetailContainer > div.generatedCode { padding: 0px; }
  div.additionalDetailContainer > div.generatedCode > h2 { padding: 0px; }
  div.additionalDetailContainer > div.generatedCode > div.accessWrapper > h2 { padding: 0px; }
  div.additionalDetailContainer > div.generatedCode > dl { padding: 0px 5px; }

  /* Access Ports & IP */
  div.additionalDetailContainer > div.generatedCode > div.accessWrapper div.accessList > div.accessPortContainer > h3.subtitle { text-align: left }
  div.additionalDetailContainer > div.generatedCode > div.accessWrapper div.accessList > div.accessPortContainer > h3.subtitle > button.removeAccessButton.btn { position: static; }
  div.additionalDetailContainer > div.generatedCode > div.accessWrapper div.accessList > div.accessPortContainer > ul.detailsSubSectionList > li > div.detailsListItemValue { white-space: normal; }

  /* CollapsibleSections */
  div.collapsibleSection > h2.subtitle { padding-left: 5px; }
  div.collapsibleSection > h3 > div.detailsListItemLabel { width: 160px; max-width: 160px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
  div.collapsibleSection > h3 > div.detailsListItemValue { padding-left: 0px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

  /* detailsSubSetionList */
  ul.detailsSubSectionList { padding: 0px 5px; }
  ul.detailsSubSectionList > li { margin: 0px 0px 15px 0px; padding: 0px 8px; }
  ul.detailsSubSectionList > li > div.detailsListItemLabel { width: 160px; max-width: 160px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
  ul.detailsSubSectionList > li > div.detailsListItemValue { padding-left: 0px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
  ul.detailsSubSectionList > li > input.detailsListItemValue { padding: 0px; }
  ul.detailsSubSectionList > li > input.detailsListItemValue.password { max-width: 150px; }

  /* Countdown Timer */
  div.statusAndCountdownContainer div.resource-countdown::before {
    width: 128px;
  }
  div.statusAndCountdownContainer div.resource-countdown::before,
  div.statusAndCountdownContainer div.progressbar::before,
  div.statusAndCountdownContainer div.resource-status::before {
    text-align: left;
  }

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

div#aboutPageView,
div#managementPageView,
div#computeResourcePageView,
div#computeResourceDetailPageView,
div#awsImportRoleContainerPageView,
div#awsCreateRoleContainerPageView,
div#awsLegacyParallelPageView,
div#credentialsPageView { min-height: calc(100vh - 86px - 56px); }

  /*
    Details Page
  */
  div#credentialsPageView { background: #fff; height: 100%; padding: 0px 0px; min-height: calc(100vh - 86px - 52px);}
  .actionIconContainer.detailsPage {padding: 20px 5px; }
  div.computeDetailsContainer > h1.pageTitle { padding: 20px 15px; }
  div.computeDetailsContainer > div.generatedCode { padding: 15px; }
  div#computeDetailsContainer { padding: 20px 0px; }
  div.computeDetailsContainer div.statusAndCountdownContainer { padding: 0px 15px; text-align: left; margin-bottom: 10px; display: block; }
  div.computeDetailsContainer div.timeoutDropdownContainer { padding: 0px 15px; text-align: left; margin-bottom: 10px; display: block; }
  div.computeDetailsContainer div.timeoutDropdownContainer .countdown-form-inline .form-control { display: inline-block; margin-left: 0px; }
  div.computeDetailsContainer div.timeoutDropdownContainer > div.countdownIndicator.form-inline.countdown-form-inline { text-align: center; }
  div.computeDetailsContainer div.resource-status.status { max-width: 760px; }
  div.computeDetailsContainer button.btn.displayNotificationsBtn::before { content: attr(title); }
  div.computeDetailsContainer > div.configNameContainer { padding: 15px;}
  div.computeDetailsContainer > div.statusAndIPContainer { display: block; padding: 10px 0px; margin: 0px 5px; }
  div.computeDetailsContainer > div.resource-status.status { display: block; margin: 0px 15px; }
  div#detailsBusySpinnerContainer { padding: 10px 15px 10px 15px; }
  li.vpcSubnet > div > div.detailsListItemLabel { width: 160px; max-width: 160px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
  li.vpcSubnet > div > div.detailsListItemValue { padding-left: 0px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
  li.vpcSubnet > ul.detailsSubSectionList { padding: 10px 0px; }
  li.vpcSubnet > ul.detailsSubSectionList > li > div.detailsListItemValue { padding-left: 0px; }

  /* Edit */
  div#editConfigDialog > div.modal-dialog { position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; padding: 0px; }
  form.editConfigForm div.wizardSection > div.collapsibleSection { padding: 0px 5px; }
  form.editConfigForm div.sectionContents { padding-bottom: 15px; }
  form.editConfigForm fieldset.section2Group { margin-top: 0px; }
  form.editConfigForm fieldset.section2Group legend { padding: 0px; margin: 0px;  }
  form.editConfigForm div.wizardSection > div.collapsibleSection div#nameContainer.stepComponentContainer { margin: 10px 0px 20px 0px; padding: 0px 8px; }
  form.editConfigForm fieldset.section2Group > div.section2InputContainer { padding: 8px 8px; }
  div.editFormContainer fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset legend,
  form.editConfigForm fieldset.section2Group > div.section2InputContainer > label { display: block; text-align: left; margin-right: 0px; width: auto; padding: 0px; font-weight: bold; color: #333; font-size: 13px; }
  form.editConfigForm > div.wizardSection ul.detailsSubSectionList > li > div.detailsListItemLabel,
  form.editConfigForm div.wizardSection > div.collapsibleSection div#nameContainer.stepComponentContainer > label,
  form.editConfigForm div.wizardSection > div.collapsibleSection div.stepComponentContainer > label { display: block; text-align: left; margin-right: 8px; width: auto; padding: 0px; white-space: normal; vertical-align: top;}
  form.editConfigForm fieldset.section2Group > div.section2InputContainer > div.passwordInputContainer { width: 80%; }
  form.editConfigForm div.wizardSection > div.collapsibleSection div.stepComponentContainer input,
  form.editConfigForm div.wizardSection > div.collapsibleSection div.stepComponentContainer select,
  form.editConfigForm fieldset.section2Group > div.section2InputContainer > select,
  form.editConfigForm fieldset.section2Group > div.section2InputContainer > textarea,
  form.editConfigForm fieldset.section2Group > div.section2InputContainer > input[type="number"],
  form.editConfigForm fieldset.section2Group > div.section2InputContainer > input[type="text"] { width: 80%; max-width: none; padding: 0px 5px;}
  form.editConfigForm input#mw-name { width: calc(100% - 50px); position: static; left: unset; }
  form.editConfigForm ul.detailsSubSectionList li select,
  form.editConfigForm ul.detailsSubSectionList li input[type="text"] { display: inline-block; width: calc(100% - 50px); padding: 0px 5px; max-width: none; }
  div.editFormContainer  form.editConfigForm div.section2InputContainer button.viewPassword { vertical-align: top; padding: 5px 0px 0px 0px; }
  form.editConfigForm  fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset legend { text-align: left; }
  form.editConfigForm  fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset div.radioBtnContainer { padding-left: 15px; }
  dl.row dt.col-sm-3 { text-align: left; justify-content: start; }
  dl.row dd { text-indent: 10px;}

  /*
    Credentials Page
  */
  div#computeResourceDetailPageView { background: #fff; height: 100%; padding: 0px 0px;}
  div.credentialInstanceContainer li.item.item-container.item-container-for-cred-page inline-popover { display: none; }

  /*
    Create/Import Credentials Pages
  */
    div#awsImportRoleContainerPageView  { background: #fff; height: 100%; padding: 0px 0px;}
    div#awsCreateRoleContainerPageView  { background: #fff; height: 100%; padding: 0px 0px;}
    /* general - both */
    div.awsImportRoleContainer div.row.importSteps div.stepContent { text-align: left; }
    div.awsImportRoleContainer div.row .text-center { text-align: left; }
    div.awsImportRoleContainer .stepTdSpacer {display: none;}
    div.awsImportRoleContainer .stepTd { padding: 0px 5px; }
    div.awsImportRoleContainer .stepTable { padding: 0px 5px; }
    div.awsImportRoleContainer input { min-width: 120px; }

  /*
    Resource Page
  */
  div#computeResourcePageView { background: #fff; height: 100%; padding: 0px 0px;}
  div#awsLegacyParallelPageView  { background: #fff; height: 100%; padding: 0px 0px;}  div.wizardContainer { padding: 0px 0px 20px 0px; }

  /*
    About Page
  */
  div#aboutPageView { padding: 0px 5px; }

/* ------------------------------------
$ Section Specific
------------------------------------ */
  .cloudCenterLogo { font-weight: 400; font-size: 24px; font-family: Roboto, Helvetica Neue,Helvetica,Arial,sans-serif; color: #fff; }

  /* Embedded Login */
  iframe.authIframe { width: 100%; height: 350px; }

/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

  div.modalContainer .modal-title {
    font:normal 400 18px/1.20 "Roboto", Arial, Helvetica, sans-serif; padding-right: 35px;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 300px;
  }

  div#connectCredentialsDialog form ul li button.viewPassword { position: relative; top: -35px; left: 290px; }

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */

  div.wizardContainer { padding: 0px 0px 20px 0px; }
  div.wizardBody {width: 100% !important; padding: 0px 0px;}
  div.wizardSummary > div.summaryListContainer { padding: 20px 5px; }
  div.wizardSection {position: relative; width: 100% !important; min-width: auto !important; display: inline-block; border: 1px solid #e6e6e6; border-radius: 4px;}
  div.wizardSection label.stepLegend { padding: 8px 0px 8px 0px; margin: 0px 8px; }
  div.wizardStepContainer > h1 {padding-top: 10px; padding-left: 15px; color: #333; font-size: 28px; text-align: left;}
  div.wizardStepsContainer > h1 {padding-top: 10px; padding-left: 0px; color: #333; font-size: 28px; text-align: left;}
  div.wizardSection > div.collapsibleSection > div.generatedCodeContainer {margin: 10px 0px 20px 0px; padding: 0px;}
  div.wizardContainer div.wizardSection > div.header { padding: 8px 5px; }
  div.wizardContainer div.wizardStepsContainer > p.editExplanation { width: 100%; padding-bottom: 25px; }
  div.wizardContainer div.generatedCodeContainer { padding: 0px 5px; }
  div.wizardContainer div.wizardSection > div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer > label {display: block; font-size: 15px; color: #333; width: auto; padding: 0px 0px 0px 0px; }
  div.wizardSection > div.collapsibleSection div.stepComponentContainer > label {margin-bottom: 1px;}
  div.wizardContainer fieldset.section2Group legend { padding: 0px; margin: 0px; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > div.passwordInputContainer { display: inline-block; position: relative; width: calc(100% - 50px); margin-left: 5px; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer  > div.passwordInputContainer > input { width: 100%; padding: 0px 5px; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > label { display: block; padding: 0px 5px; width: auto; text-align: left; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > select,
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > textarea,
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset,
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > input[type="number"],
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > input[type="text"] { width: calc(100% - 50px); padding: 0px 5px; margin-left: 5px; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset legend { text-align: left; white-space: nowrap; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset div.radioBtnContainer { padding-left: 15px; white-space: nowrap; }
  div.wizardContainer div.wizardSection > div.collapsibleSection div.stepComponentContainer input,
  div.wizardContainer div.wizardSection > div.collapsibleSection div.stepComponentContainer select { display: inlie-block; width: calc(100% - 50px); padding: 0px 5px; }
  div.wizardSection > div.collapsibleSection div.stepComponentContainer > p.finePrint {margin-bottom: 1px; white-space: nowrap; overflow: hidden; max-width: 100%; width: 100%; text-overflow: ellipsis; }
  div.wizardSection > div.collapsibleSection div.stepComponentContainer input,
  div.wizardSection > div.collapsibleSection div.stepComponentContainer select {width: 100%; margin-top: 2px;}
  div.wizardStepContainer { padding: 0px !important; position: relative;}
  div.wizardSection > div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer > select {margin-left: 0px;}
  div.wizardSection > div.collapsibleSection div.stepComponentContainer div.createNewCredentialContainer input[type="radio"] {width: auto;}
  div.wizardSection > div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer > select {display: inline-block; padding: 4px; width: 100%; max-width:none;}
  div.wizardSection > div.collapsibleSection div.stepComponentContainer div.pickCredentialContainer > button {display: inline-block; padding: 2px 5px; margin-left: 0px; margin-top: 10px;}
  div.wizardBody > div.wizardStepContainer div.buttonContainer.stepComponentContainer {position: relative; }
  div.wizardContainer.showall div.wizardBody > div#step1Container div.buttonContainer.stepComponentContainer > button.configInfoButton,
  div.wizardContainer.showall div.wizardBody > div#step2Container div.buttonContainer.stepComponentContainer > button.configInfoButton {display: inline-block; margin: 0px;background-color: inherit; border: 1px solid #0076A8; color: #0076A8; padding: 5px;}
  div.wizardContainer div.wizardBody > div#step1Container div.buttonContainer.stepComponentContainer > button.configInfoButton,
  div.wizardContainer div.wizardBody > div#step2Container div.buttonContainer.stepComponentContainer > button.configInfoButton {display: inline-block;margin: 0px;background-color: inherit; border: 1px solid #0076A8; color: #0076A8; padding: 5px;}
  div.wizardSummary > div#summaryButtons > button { width: auto; min-width: 60px;}
  div.wizardContainer div.wizardSection > div.collapsibleSection div.stepComponentContainer div.cloudPlatformChoice > button.cloudChoice { width: 130px; }
  div.wizardContainer div.wizardSection > div.collapsibleSection div.stepComponentContainer > label {display: block; width: auto; margin: 0px; padding: 0px 20px 0px 0px; text-align: left; }
  div.generatedCodeContainer { padding: 0px 5px; }
  div.wizardContainer fieldset.section2Group > div.section2InputContainer button.viewPassword { vertical-align: bottom; padding-bottom: 3px; }
  div.wizardSummary h1 { padding: 5px 0px; }
  form[name="editConfigForm"] div.wizardSection > div.collapsibleSection div.stepComponentContainer > p.finePrint {white-space: normal;}
  .buttonContainer > button { min-width: 60px; margin-right: 2px; }
  fieldset.section2Group > div.section2InputContainer > p.finePrint {margin-bottom: 1px; white-space: nowrap; overflow: hidden; max-width: 100%; width: 100%; text-overflow: ellipsis; }
  form[name="editConfigForm"] fieldset.section2Group > div.section2InputContainer > p.finePrint {white-space: normal;}
  fieldset.section2Group > div.section2InputContainer > textarea,
  fieldset.section2Group > div.section2InputContainer > select,
  fieldset.section2Group > div.section2InputContainer > input[type="number"],
  fieldset.section2Group > div.section2InputContainer > input[type="text"] { width: 100%; }
  fieldset.section2Group > div.section2InputContainer > input.password { width: auto; }
  fieldset.section2Group legend { padding-left: 10px; }
  fieldset.section2Group > div.section2InputContainer > div.validationErrorImage { left:auto; right: 15px; }
  fieldset.section2Group > div.section2InputContainer > div.validationErrorImage.fineprint { top: 63px; }
  /* Config Info Summary Container */
  div.configInfoContainer {display:none !important;}
  div.configInfoContainer.popupwin { display: inline-block !important; background-color: #fff; position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; width:auto !important; z-index: 3;}
  div.configInfoContainer > div.configInfoHeader {position: relative;}
  div.configInfoContainer > div.configInfoHeader > button.btn-close { display: inline-block; position: absolute; right: 5px; top: 0px; color: #000; opacity: 1;}
  div.summaryListContainer { padding-left: 0px; padding-right: 0px; }
  /* Edit */
  div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > label,
  div.editFormContainer  form.editConfigForm div.section2InputContainer.details > label { margin-left: 0px;}
  div.wizardContainer fieldset.section2Group > div.section2InputContainer > p.finePrint,
  div.editFormContainer  form.editConfigForm div.section2InputContainer.details > p.finePrint {margin-left: 15px; white-space: normal; overflow: visible; }
  div.editFormContainer  form.editConfigForm div.section2InputContainer.details > select,
  div.editFormContainer  form.editConfigForm div.section2InputContainer.details > textarea,
  div.editFormContainer  form.editConfigForm div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > input[type="number"],
  div.editFormContainer  form.editConfigForm div.section2InputContainer.details > input[type="text"] { margin-left: 15px; }
  div.editFormContainer fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset { width: 80%; }
  div.editFormContainer fieldset.section2Group > div.section2InputContainer > fieldset.radioCheckboxFieldset div.radioBtnContainer { padding-left: 15px; white-space: nowrap; }

  div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > select,
  div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > textarea,
  div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > input[type="password"],
  div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > input[type="number"],
  div.wizardContainer fieldset.section2Group > div.section2InputContainer.details > input[type="text"] { margin-left: 15px; }
  div.editFormContainer  form.editConfigForm div.section2InputContainer button.viewPassword,
  div.wizardContainer fieldset.section2Group > div.section2InputContainer button.viewPassword { position: absolute; top: 0px; right: 0px; }

  div#autoCompleteSpinnerContainer { width: 100vw; }

  div.additionalDetailHeader { padding: 0px 0px 5px 0px; }

  div.wizardStepWrapper div.additionalDetailHeader { margin: 0px; padding: 10px 0px 0px 0px; }
  div.wizardContainer div.sectionContents { padding-bottom: 10px; }
  div.wizardContainer fieldset.section2Group { margin: 0px; }

/* ------------------------------------
$ Inline Validation
------------------------------------ */

  div.section2InputValidationContainer {
    width: calc(100% - 50px);
  }

  .editFormContainer div.section2InputValidationContainer {
    width: calc(100% - 60px);
  }

  div.section2InputValidationContainer fieldset.radioCheckboxFieldset {
    padding: 0px 10px;
    width: 100%;
  }

  div.wizardContainer fieldset.section2Group > div.section2InputContainer > div.validationErrorImage.fineprint {
    top: 39px; left: 230px;
  }
  .inline_form_error_msg { margin-left: 5px; }
  .inline_form_error_arrow { margin-left: 10px; }

    /* Create Wizard Step1 mlNameInput-only */
    div#nameContainer .inline_form_error_msg.mlNameInput {
          left: 0px;
    }

    div#nameContainer .inline_form_error_arrow {
          left: 5px;
    }

    div#nameContainer .validationErrorImage.form-control-feedback.fineprint {
      top: 28px;
      right: 25px;
    }

    div#nameContainer .inline_form_error_msg {
      position: static;
      left: unset;
    }


/* ------------------------------------
$ Custom Components
-------------------------------------*/
stack-rollback-choice .labelContainer,
license-manager-simple .labelContainer,
license-manager .labelContainer {
  display: block;
  padding: 0px 5px;
  width: auto;
  text-align: left;
  height: auto;
  min-height: unset;
}

stack-rollback-choice div.labelContainer > label,
license-manager-simple div.labelContainer > label,
license-manager div.labelContainer > label {
  position: static;
  padding: 0px;
  text-align: left;
}

stack-rollback-choice .section2InputValidationContainer,
license-manager-simple .section2InputValidationContainer,
license-manager .section2InputValidationContainer {
  left: 0;
}

license-manager fieldset.licenseManagerFieldset div.radioButtonContainer {
  display: block;
}

license-manager fieldset.licenseManagerFieldset {
  padding: 0 0 0 10px;
  width: calc(100% - 50px);
}
div.editFormContainer  license-manager fieldset.licenseManagerFieldset {
  width: calc(100% - 60px);
}

div.editFormContainer form.editConfigForm div.section2InputContainer license-manager .btn.toggleDetails { padding-left: 7px; }

network-access {
  display: block;
}

mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster { padding-bottom: 0px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .labelContainer,
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .labelContainer label { display: block; padding: 0px 3px; width: auto; text-align: left; height: auto; min-height: unset; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer { width: calc(100% - 50px); padding-left: 11px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.checkboxContainer { width: auto; cursor: pointer; padding: 3px 5px 3px 10px; text-align: left; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.checkboxContainer input[type="checkbox"] { vertical-align: top; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.checkboxLabelContainer { padding-left: 0px; width: calc(100% - 82px); }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster legend { text-align: left; width: 100%; padding: 8px 6px 0px 0px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.machinesInClusterInputs,
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts { display: block; margin-left: 0px; padding-left: 8px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer { padding-left: 11px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.section2InputValidationContainer.siblingAfterNote { width: auto; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset inline-popover { position: absolute; right: 10px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.standardCheckboxLabelContainer + inline-popover { position: absolute; right: 10px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.section2InputValidationContainer.siblingAfterNote.disabled + div.afterNote + inline-popover { position: absolute; right: 10px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer { position: relative; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.inputContainer:last-child { padding: 8px 0px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.inputContainer:last-of-type {  padding-left: 0px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts:first-of-type div.inputContainer:last-of-type { padding-left: 0px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset div.machinesInClusterInputs div.section2InputValidationContainer:has(select) { width: calc(100% - 50px); padding-left: 11px; padding-right: 15px; margin-right: 0px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset fieldset.workersInCluster div.workersInClusterCounts div.labelContainer.checkboxLabelContainer { padding: 0px; padding-left: 3px; }
mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .labelContainer, mw-cluster-worker-picker fieldset.mwClusterWorkerPickerFieldset .labelContainer label { padding-left: 0px; }

editable-text {
  padding-right: 15px;
}
editable-text div.editableTextElementContainer div.editNameContainer.usingButtons form input { min-width: none; }
editable-text div.editableTextElementContainer div.editNameContainer .validationErrorImage.form-control-feedback.fineprint { top: 9px; right: 15px; }
editable-text div.editableTextElementContainer div.editNameContainer.usingButtons .validationErrorImage.form-control-feedback.fineprint { top: 6px; right: 15px; }
editable-text div.editableTextElementContainer .inline_form_error_msg { margin: 0px; }
editable-text div.editableTextElementContainer .inline_form_error {position: static; }
.attribute.resource-machine-name.editableText { overflow: hidden; }
a.goToResourceDetail { max-width: 150px; }



.editFormContainer network-access div.labelContainer,
network-access div.labelContainer {
  display: block;
  padding: 0px 5px;
  width: auto;
  text-align: left;
  height: auto;
  min-height: unset;
}

.editFormContainer network-access > .labelContainer,
network-access div.labelContainer > label {
  position: static;
  padding: 0px !important;
  text-align: left;
}

.editFormContainer network-access fieldset.networkAccessFieldset,
network-access fieldset.networkAccessFieldset {
  padding: 0 0 0 10px;
  width: 100%;
}

.editFormContainer network-access fieldset.networkAccessFieldset div.radioButtonContainer,
network-access fieldset.networkAccessFieldset div.radioButtonContainer {
  display: block;
  width: 100%;
  padding: 5px 0px;
}

.editFormContainer network-access .networkAccessInputContainer,
network-access .networkAccessInputContainer {
  display: block;
  padding: 5px 0px 5px 15px;
}

.editFormContainer network-access .networkAccessInputContainer div.labelContainer,
network-access div.networkAccessInputContainer div.labelContainer {
  display: block;
  padding: 0px;
  width: 100%;
  text-align: left;
}

div.editFormContainer form.editConfigForm div.section2InputContainer network-access .btn.toggleDetails { padding: 0px 7px; }

ssl-certificate .labelContainer {
  display: block;
  padding: 0px 5px;
  width: auto;
  text-align: left;
  height: auto;
  min-height: unset;
}
.editFormContainer ssl-certificate .labelContainer {
  margin-right: 0px;
  padding: 0px;
}

ssl-certificate div.labelContainer > label {
  position: static;
  padding: 0px;
  text-align: left;
}

ssl-certificate .section2InputValidationContainer { left: 0px; }

ssl-certificate fieldset.sslCertificateChooserFieldset {
  width: 100%;
}

ssl-certificate inline-popover a.btn.toggleDetails {
  margin: inherit;
}

/* CreateNewSSLCertificateDialog */
create-new-ssl-cert-dialog div.modal.fade.show {
  padding-right: 0px !important;
}

create-new-ssl-cert-dialog div.modal.fade.show div.modal-dialog {
  width: 100%;
}

div.createSSLCertContainer div.section2InputValidationContainer {
  padding: 0px 5px;
}

div.createSSLCertContainer div.section2InputValidationContainer label {
  display: block;
}

div.createSSLCertContainer div.section2InputValidationContainer input {
  display: block;
  width: 100%;
  padding: 0px 5px;
}

create-new-ssl-cert-dialog inline-validation div.inline_form_error_arrow {
  position: relative;
  left: 0px;
}

create-new-ssl-cert-dialog inline-validation div.inline_form_error_msg.sslNameInput {
  position: relative;
  left: 0px;
}

create-new-ssl-cert-dialog inline-validation div.validationErrorImage.form-control-feedback.fineprint {
  right: 15px;
  top: 26px;
}

machine-type-chooser-with-finder .labelContainer {
  display: block;
  padding: 0px 5px;
  width: auto;
  text-align: left;
  height: auto;
  min-height: unset;
}
.editFormContainer machine-type-chooser-with-finder .labelContainer {
  margin-right: 0px;
  padding: 0px;
}

machine-type-chooser-with-finder div.labelContainer > label {
  position: static;
  padding: 0px;
  text-align: left;
}

machine-type-chooser-with-finder .section2InputValidationContainer { left: 0px; }

machine-type-chooser-with-finder fieldset.machineChooserFieldset {
  width: 100%;
}

machine-type-chooser-with-finder inline-popover a.btn.toggleDetails {
  margin: inherit;
}

h2.machineTypeChooserHeader {
  padding-left: 5px;
}

machine-type-chooser-with-finder .editChoicesButtonContainer {
  padding-top: 0px;
  padding-left: 5px;
}

machine-type-chooser-with-finder div.radioButtonContainer div.col-12 {
  padding-left: 5px;
}

machine-type-chooser-with-finder div.editChoicesContainer div.sectionContents {
  padding-bottom: 0px;
}

machine-type-chooser-with-finder div.editChoicesContainer div.buttonContainer.stepComponentContainer {
  padding-top: 12px;
}

machine-type-chooser-with-finder div.editChoicesContainer div.section2InputValidationContainer {
  width: calc(100% - 35px);
}

machine-type-chooser-with-finder div.wizardContainer div.sectionContents {
  padding-bottom: 0px;
}

/* MachineTypeFinderDialog */
machine-type-finder-dialog div.modal.fade.show {
  padding-right: 0px !important;
}

machine-type-finder-dialog div.modal.fade.show div.modal-dialog {
  width: 100%;
}

div.machineTypeFinderContainer div.section2InputValidationContainer {
  padding: 0px 5px;
}

div.machineTypeFinderContainer div.section2InputValidationContainer label {
  display: block;
}

div.machineTypeFinderContainer div.section2InputValidationContainer input {
  display: block;
  width: 100%;
  padding: 0px 5px;
}

machine-type-finder-dialog inline-validation div.inline_form_error_arrow {
  position: relative;
  left: 0px;
}

machine-type-finder-dialog inline-validation div.inline_form_error_msg.machineNameInput {
  position: relative;
  left: 0px;
}

machine-type-finder-dialog inline-validation div.validationErrorImage.form-control-feedback.fineprint {
  right: 15px;
  top: 26px;
}


/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

  .importantMessage,
  .importantMessageInfo,
  .importantMessageWarning,
  .importantMessageError {
    width:auto; font-size: 15px; position: absolute; top: 52px; left: max(225px, calc(50% - 34%)); z-index: 4;
  }
  .modal-dialog,
  .modal:before { display: block !important; }
  .modal:before { height: auto; }

  .alert.collapsed span.severityLabel { display: none; }

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */

/* ------------------------------------
$ Custom Elements
------------------------------------ */

/* auto-login */
auto-login { position: relative; left: 0px; }
auto-login label.paramLabel {
  display: block;
  padding: 0px 5px;
  width: auto;
  text-align: left;
}

auto-login div.autoLoginRadioButtonLayout  { width: calc(100% - 50px); }

autologin-licensemanager select.disabled { cursor: not-allowed; opacity: .45;}
autologin-licensemanager .section2Group > .section2InputContainer > .section2InputContainer {
margin-left: 120px;
}
autologin-licensemanager div.licenseManagerOptionSelectors {
position: relative;
display: inline-block;
width: 100%;
vertical-align: top;
}